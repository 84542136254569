import React from "react";
import { connect } from "react-redux";
import Button from "../../shared/controls/button";
import TextInput from "../../shared/controls/text-input";
import ChipContainer from "../../shared/component/chip-container";
import NormativityTableItem, {
  DocumentTypes,
} from "../../shared/component/fleet/normativity-table-item";
import SVGIcon, { icons } from "../../shared/svg-icon";
import SelectInput from "../../shared/controls/select-input";
import InputProfileImage2 from "../../shared/controls/input-profile-image-2";
import { validatePassword, validateRFC } from '../../shared/validate';
import {
  loadOperatorTypes,
  loadFleets,
  createOperator,
  loadOperateType,
  addTagToOperator,
  createNormativityDataForOperator,
  postUploadImageOperator,
  loadOperatorData,
  loadOperatorNormativities,
  loadOperatorTags,
  loadImageOperatorNormativities,
  updateOperator,
  updateNormativityDataForOperator,
  deleteNormativityImagesForOperator,
  deleteTagInOperador,
  postValidateId,
  postValidateLicense,
  postValidateAptitude,
  postValidateSecurity,
  loadExpeditionCenter,
} from "../../redux/reducers/fleet-reducer";
import {
  loadSession,
  addToast,
  setFleetCatalogTabSeleted,
} from "../../redux/reducers/global-catalog.reducer";
import {
  createUserMobile,
  assignCompany,
  loadMobileUser,
  postValidateUsername,
  updateUserMobile,
  CREATE_USER_RESPONSE,
  CREATE_USER_ERROR,
} from "../../redux/reducers/register.reducer";
import { loadTags } from "../../redux/reducers/tags.reducer";
import ReactTooltip from "react-tooltip";
import { validators } from "../../shared/validate";
import ErrorDialog from "../../shared/dialog/error-dialog";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import { getUrlsEnv } from "../../shared/backend-api";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { updateOperatorNormativityStatus } from "../../redux/reducers/fleet-reducer";
import Table from "../../shared/component/table";
import Panel from "../../shared/component/panel";
// import { getEntityItems } from "../../redux/api/helpers";
import SelectMultipleInput from "../../shared/controls/select-multiple-input";
import { createEntity, partialEntity, readEntities, getEntityItems, readEntity, deleteEntity, SaveItemCounter } from "../../redux/api";
import { handleWSError, handleWSErrorWithKeys } from '../../shared/utils';

let typingTimer;
const MAXSIZE = 12;

class FleetOperatorView extends React.Component {
  constructor(props) {
    super(props);

    this.loaded = false;
    this.confirmDeleteAction = () => {};
    this.fetchCounter = 0;
    this.responseCounter = 0;

    this.state = {
      allowNullNorm: false,
      // validForm: false,
      modeEdit: false,
      onFocused: false,
      pwdType: true,
      pwdConfType: true,

      dupliedUsername: false,
      dupliedId: false,
      dupliedLicense: false,
      dupliedAptitude: false,
      dupliedSecurity: false,

      ownUsername: null,
      ownId: null,
      ownLicense: null,
      ownAptitude: null,
      ownSecurity: null,

      dataId: null,

      profileImage: props.profileImage || null,

      name: "",
      lastname: "",
      birthDate: "",
      age: "",
      celPhone: "",
      ID: "",
      rControl: "",
      admissionDate: "",
      userName: "",
      password: "",
      pwdConfirm: "",
      fleetTags: [],
      curp: "",

      license: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        expeditionLabel: "Desde",
        expirationLabel: "Hasta",
        expirationErrors: [],
        isConsidered: true
      },
      licenseNumber: "",
      typeCategory: "",
      expeditionCenter: "",
      vigencyLastEndorsement: "",
      expirationLastEndorsement: "",
      expeditionDate: "",

      fitness: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        expeditionLabel: "",
        expirationLabel: "",
        expirationErrors: [],
        isConsidered: true
      },
      preventiveMedicine: "",
      lastMedicalTestType: "Examen Psicofísico Integral",
      vigencyLastMedicalTest: "",
      expirationLastMedicalTest: "",

      ableTo: 0,

      medical: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        expeditionLabel: "Fecha de alta",
        expirationLabel: "",
        isConsidered: true
      },
      medicalNumber: "",
      medicalExpeditionDate: "",

      showError: false,
      msgError: "",

      normLicenseId: -1,
      normFitnessId: -1,
      normMedicalId: -1,

      showConfirmDialog: false,
      fileIdToRemove: 0,
      normativityIdToRemove: 0,

      isPasswordEqual: true,
      crop: {
        unit: "%",
        width: 300,
        aspect: 1 / 1,
      },
      editImage: false,
      imageToCrop: null,

      showFilesMessageError: false,
      showFilesSizeMessageError: false,

      licenseExpeditionDate: "",
      licenseStartDate: "",
      licenseEndDate: "",

      fitnessStartDate: "",
      fitnessEndDate: "",

      medicalDate: "",
      tagsData: [],
      normativityUpdated: false,
      normativityCount: 0,
      normativityCountTotal: [],

      taxInfoId: '',

      rfc: "",
      street: "",
      numExt: "",
      numInt: "",
      suburb: 0,
      postalCode: "",
      locality: 0,
      municipality: 0,
      stateOperator: 0,
      country: 0,
      reference: "",
      postalCodeId: 0,

      dataBackup: {
        licenseExpedition: '',
        licenseExpiration: '',
        licenseTypeCategory: '',
        licenseExpeditionCenter: '',
        licenseExpeditionDate: '',
        licenseAbleTo: '',
        aptitudeExpedition: '',
        aptitudeExpiration: '',
        preventiveMedicine: ''
      },
      normativitiesChangedConfirmation: false,
      companiesLinked: 1,
      normativityImagesToDelete: {"medical": [], "fitness": [], "license": []}
    };
  }

  componentDidMount() {
    this.props.loadCountries();

    if(this.props.country && this.props.country !== 'México'){
      this.setState({allowNullNorm: true})
    }

    // this.unblock = this.props.history.block((targetLocation) => {
    //   if (this.state.normativityUpdated) {
    //     this.setState({ saveExit: true });
    //     this.props
    //       .updateOperatorNormativityStatus(
    //         this.props.company.id,
    //         this.props.fleet ? this.props.fleet.id : "",
    //         this.state.operatorUser.id
    //       )
    //       .then((r) => {
    //         this.setState({ saveExit: false, normativityUpdated: false });
    //         this.props.history.goBack();
    //       });
    //   }
    //   return !this.state.normativityUpdated;
    // });
  }

  // componentWillUnmount() {
  //   this.unblock();
  // }

  componentDidUpdate() {
    if (
      this.fetchCounter > 0 &&
      this.fetchCounter === this.responseCounter &&
      this.props.toast === null 
      //&& this.state.normativityCountTotal.length === this.state.normativityCount
    ) {
      this.props.showToast({
        text: this.state.modeEdit
          ? "Se actualizó con éxito el operador"
          : "Se registró con éxito el operador",
      });
      
      this.props.history.goBack();
    }

    // if (this.validateForm() !== this.state.validForm)
    //   this.setState({ validForm: !this.state.validForm });

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.operatorId &&
      this.props.match.params.operatorId > 0 &&
      this.props.fleet &&
      !this.loaded
    ) {
      this.loaded = true;

      const fleetId = this.props.fleet ? this.props.fleet.id : "";
      this.props
        .loadOperatorData(this.props.match.params.operatorId, fleetId)
        .then((data) => {
          const { response = {} } = data;
          const birth = String(response.birth_date).split("-") || "";
          const hire = String(response.hire_date).split("-") || "";

          this.state.dataBackup.licenseTypeCategory = response.licence_type ? `${response.licence_type}` : "";
          this.state.dataBackup.licenseAbleTo = response.fit_to_operate ? `${response.fit_to_operate}` : 0;
          this.state.companiesLinked = response.companies_linked || 1;

          this.setState({
            operatorUser: response,
            modeEdit: true,
            rControl: response.r_control || "",
            birthDate: response.birth_date ? `${birth[1]}/${birth[2]}/${birth[0]}` : '',
            typeCategory: response.licence_type
              ? `${response.licence_type}`
              : "",
            ableTo: response.fit_to_operate ? `${response.fit_to_operate}` : 0,
            celPhone: response.mobile_phone || "",
            ID: response.identification || "",
            admissionDate: response.hire_date ? `${hire[1]}/${hire[2]}/${hire[0]}` : '',
            curp: response.curp,
            ownId: response.identification || null,
          });

          this.props.loadMobileUser(response.user).then((mobileUserData) => {
            const { data = {} } = mobileUserData;
            const { files } = getUrlsEnv();
            const profileImage =
              `${files}${data.profile_image}` || "";

            var taxInfo = data.tax_info ? `${data.tax_info}` : '';

            this.setState({
              licenseNumber: data.username || "",
              taxInfoId: taxInfo,
              mobileUser: data,
              name: data.first_name || "",
              lastname: data.last_name || "",
              userName: data.username || "",
              password: "",
              pwdConfirm: "",
              profileImage: data.profile_image ? profileImage : "",

              ownUsername: data.username || null,
            });

            if (taxInfo !== '') {
              this.props.loadTaxInfo({
                company_id: this.props.fleet?.company || 0,
                fleet_id: fleetId,
                id: response.user,
                tax_info_id: taxInfo
              }, {
                onSuccess: (result) => {
                  this.setState({
                    rfc: result?.data?.fiscal_number ?? '',
                    street: result?.data?.street ?? '',
                    numExt: result?.data?.exterior_number ?? '',
                    numInt: result?.data?.interior_number ?? '',
                    suburb: result?.data?.suburb?.id ?? 0,
                    postalCode: result?.data?.zip_code?.cp ?? 0,
                    postalCodeId: result?.data?.zip_code?.id ?? 0,
                    locality: result?.data?.locality?.id ?? 0,
                    municipality: result?.data?.city?.id ?? 0,
                    stateOperator: result?.data?.state?.id ?? 0,
                    country: result?.data?.country?.id ?? 0,
                    reference: result?.data?.reference ?? '',
                  }, () => {
                    this.getZipCodeInfoInit();
                  });
                }
              });
            }

          });
        });

      this.props
        .loadOperatorTags(fleetId, this.props.match.params.operatorId)
        .then((data) => {
          const { response = [] } = data;
          let newTags = [];

          response.forEach((item) => {
            if (!newTags.includes(item)) {
              newTags.push(item.tags.id);
            }
          });

          this.setState({
            fleetTags: newTags,
            tagsData: this.props.tagsData,
          });
        });

      this.props
        .loadOperatorNormativities(fleetId, this.props.match.params.operatorId)
        .then((data) => {
          const { response = [] } = data;

          response.forEach((item) => {
            switch (item.type) {
              case 1:

                this.state.dataBackup.licenseExpeditionCenter = this.capitalizeFirstLetter(item.expedition_center || "");
                this.state.dataBackup.licenseExpeditionDate = item.expedition_date || "";
                this.state.dataBackup.preventiveMedicine = item.medical_record_data || "";

                this.setState({
                  // licenseNumber: item.code || "",
                  preventiveMedicine: item.medical_record_data || "",
                  normLicenseId: item.id || -1,
                  expeditionCenter: this.capitalizeFirstLetter(item.expedition_center || ""),
                  vigencyLastEndorsement: item.expedition || "",
                  expirationLastEndorsement: item.expiration || "",
                  expeditionDate: item.expedition_date || "",
                  licenseExpeditionDate: item.expedition_date || "",
                  licenseStartDate: item.expedition || "",
                  licenseEndDate: item.expiration || "",

                  ownLicense: item.code || null,
                });

                this.props
                  .loadImageOperatorNormativities(
                    fleetId,
                    this.props.match.params.operatorId,
                    item.id
                  )
                  .then((normImage) => {
                    const images = normImage.response || [];

                    let filesToUload = [];

                    images.forEach((item) => {
                      filesToUload.push({
                        name: item.original_name + " (" + item.file_size + ")",
                        idNormativityImage: item.id,
                      });
                    });

                    this.state.dataBackup.licenseExpedition = this.checkNull(item.expedition) || "";
                    this.state.dataBackup.licenseExpiration = this.checkNull(item.expiration) || "";

                    this.setState({
                      license: {
                        ...this.state.license,
                        id: item.id,
                        number: "",
                        expedition: this.checkNull(item.expedition) || "",
                        expiration: this.checkNull(item.expiration) || "",
                        expeditionLabel: "Desde",
                        expirationLabel: "Hasta",
                        files: filesToUload,
                        isConsidered: true
                      },
                    });
                  });

                break;

              case 2:
                this.setState({
                  normFitnessId: item.id || -1,
                  lastMedicalTestType: item.expedition_center || "",
                  vigencyLastMedicalTest: item.expedition || "",
                  expirationLastMedicalTest: item.expiration || "",
                  fitnessStartDate: item.expedition || "",
                  fitnessEndDate: item.expiration || "",

                  ownAptitude: item.code || null,
                });

                this.props
                  .loadImageOperatorNormativities(
                    fleetId,
                    this.props.match.params.operatorId,
                    item.id
                  )
                  .then((normImage) => {
                    const images = normImage.response || [];
                    let filesToUload = [];

                    images.forEach((item) => {
                      filesToUload.push({
                        name: item.original_name + " (" + item.file_size + ")",
                        idNormativityImage: item.id,
                      });
                    });

                    this.state.dataBackup.aptitudeExpedition = this.checkNull(item.expedition) || "";
                    this.state.dataBackup.aptitudeExpiration = this.checkNull(item.expiration) || "";

                    this.setState({
                      fitness: {
                        id: item.id,
                        number: "",
                        expedition: this.checkNull(item.expedition) || "",
                        expiration: this.checkNull(item.expiration) || "",
                        expeditionLabel: "",
                        expirationLabel: "",
                        files: filesToUload,
                        isConsidered: true
                      },
                    });
                  });

                break;

              case 3:
                this.setState({
                  normMedicalId: item.id || -1,
                  medicalNumber: item.code || "",
                  medicalExpeditionDate: item.expedition || "",
                  medicalDate: item.expedition || "",

                  ownSecurity: item.code || null,
                });

                this.props
                  .loadImageOperatorNormativities(
                    fleetId,
                    this.props.match.params.operatorId,
                    item.id
                  )
                  .then((normImage) => {
                    const images = normImage.response || [];
                    let filesToUload = [];

                    images.forEach((item) => {
                      filesToUload.push({
                        name: item.original_name + " (" + item.file_size + ")",
                        idNormativityImage: item.id,
                      });
                    });

                    this.setState({
                      medical: {
                        id: item.id,
                        number: item.code || '',
                        expedition: this.checkNull(item.expedition_date) || "",
                        expiration: this.checkNull(item.expiration) || "",
                        expeditionLabel: "Fecha de alta",
                        expirationLabel: "",
                        files: filesToUload,
                        isConsidered: true
                      },
                    });
                  });

                break;

              default:
                break;
            }
          });
        });
    }
  }

  checkNull = (value) => {
    if (typeof value !== "undefined") {
      return value;
    }
    return "";
  };

  // getLicenseType = (type) => {
  //   switch (type) {
  //     case 1:
  //       return "Solo Sencillo";
  //     case 2:
  //       return "Solo Full";
  //     case 3:
  //       return "Ambos";

  //     default:
  //       return "";
  //   }
  // };

  handleLeave = () => {
    this.setState({ onFocused: true });
  };

  capitalizeFirstLetter(value) {
    let string = `${value}`;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  actionCloseDialog = () => {
    this.setState({
      showError: false,
      showConfirmDialog: false,
      fileIdToRemove: null,
      showFilesMessageError: false,
      showFilesSizeMessageError: false,
    });
  };

  isPasswordValid = () => {
    if (this.state.modeEdit) {
      return true;
    } else {
      return (this.state.password.length > 0 && this.state.pwdConfirm.length > 0 && this.state.password === this.state.pwdConfirm) && validatePassword(this.state.password);
    }
  }

  validCurrentDate = (date_string) => {

    if (date_string === '') {
      return true;
    }

    let tempDate = new Date(date_string);

    let year = tempDate.getFullYear();
    let month = tempDate.getMonth() + 1;
    let day = tempDate.getDate();

    let new_date = new Date(`${month}/${day}/${year}`);
    let currentDate = new Date();

    return new_date < currentDate;
  }

  validateForm = () => {
    if (
      this.validCurrentDate(this.state.expeditionDate || '') &&
      this.validCurrentDate(this.state.medical?.expedition || '') &&
      this.validCurrentDate(this.state.license?.expedition || '') &&
      this.validCurrentDate(this.state.fitness?.expedition || '') &&
      this.state.licenseNumber.length > 0 &&
      this.isPasswordValid() &&
      this.state.name.length > 0 &&
      this.state.lastname.length > 0 &&
      // this.state.ID.length > 0 &&
      (
        (this.state.name.length +
        this.state.lastname.length) <= 39
      ) &&
      !this.state.dupliedLicense &&
      // !this.state.dupliedId &&
      this.validateTagData() &&
      this.isRfcValid()
    ) {
      return true;
    } else return false;
  };

  isRfcValid = () => {
    return(
      (
        this.state.rfc.length === 0 && this.state.country === 0 && this.state.suburb === 0
      ) || (
        this.state.rfc.length > 0 && validateRFC(this.state.rfc) && this.state.country > 0 && this.state.suburb > 0
      )
      // (
      //   this.state.rfc.length > 0 && this.state.street.length > 0 && this.state.numExt.length > 0 &&
      //   this.state.suburb > 0 && this.state.postalCode.length > 0 &&
      //   this.state.municipality > 0 && this.state.stateOperator > 0 &&
      //   this.state.country > 0 && this.state.reference.length > 0 &&
      //   validateRFC(this.state.rfc)
      // )
    );
  }

  getErrors = () => {
    let errors = "";

    if (!this.validateForm()) {
      errors = "Por favor revisa los siguientes campos:<br/>";
    }

    errors +=
      this.state.licenseNumber.length === 0
        ? `<b>Ingrese el número de licencia<b/><br/>`
        : "";

    if (!this.state.modeEdit) {
      errors +=
        this.state.password.length === 0
          ? `<b>Ingrese la contraseña<b/><br/>`
          : "";

      errors +=
        this.state.password.length !== 0 && !validatePassword(this.state.password)
          ? `<b>La contraseña no cumple las reglas<b/><br/>`
          : "";

      errors +=
        this.state.pwdConfirm.length === 0
          ? `<b>Ingrese confirmar contraseña<b/><br/>`
          : "";

      if (this.state.password.length > 0 && this.state.pwdConfirm.length > 0) {
        errors +=
          this.state.pwdConfirm !== this.state.password
            ? `<b>Las contraseñas deben coincidir<b/><br/>`
            : "";
      }
    }

    errors +=
      this.state.name.length === 0
        ? `<b>Ingrese el nombre del operador<b/><br/>`
        : "";
    errors +=
      this.state.lastname.length === 0
        ? `<b>Ingrese apellidos del operador<b/><br/>`
        : "";

    errors +=
      (this.state.name.length +
      this.state.lastname.length) > 39
      ? "<b>El nombre completo del operador no puede ser mayor a 39 caracteres<b/><br/>"
      : "";

    // errors +=
    //   this.state.ID.length === 0
    //     ? `<b>Ingrese INE ó DNI del operador<b/><br/>`
    //     : "";
      
    errors += !this.validateTagData()
      ? "<b>Seleccione una etiqueta<b/><br/>"
      : "";

    if (this.state.license.files.length > 0) {
      errors += this.state.dupliedLicense
        ? `<b>La licencia "${this.state.licenseNumber}" ya existe<b/><br/>`
        : "";
    }

    if (!this.isRfcValid()) {

      if (this.state.rfc.length == 0) {
        errors += `<b>Ingrese el rfc<b/><br/>`;
      } else {
        if (!validateRFC(this.state.rfc)) {
          errors += `<b>El rfc no es valido<b/><br/>`;
        }
      }

      errors +=
        this.state.suburb === 0
          ? `<b>Ingrese la colonia del rfc<b/><br/>`
          : "";

      errors +=
        this.state.country === 0
          ? `<b>Ingrese el país del rfc<b/><br/>`
          : "";
    }

    if (!this.validCurrentDate(this.state.expeditionDate || '')) {
      errors += `<b>Antigüedad desde - La fecha no puede ser mayor a el día de hoy<b/><br/>`;
    }

    if (!this.validCurrentDate(this.state.medical?.expedition || '')) {
      errors += `<b>IMSS - La fecha no puede ser mayor a el día de hoy<b/><br/>`;
    }

    if (!this.validCurrentDate(this.state.license?.expedition || '')) {
      errors += `<b>Licencia - La fecha no puede ser mayor a el día de hoy<b/><br/>`;
    }

    if (!this.validCurrentDate(this.state.fitness?.expedition || '')) {
      errors += `<b>Constancia - La fecha no puede ser mayor a el día de hoy<b/><br/>`;
    }

    return errors;
  };

  isNormativityValid = (norm) => {
    if (this.state.allowNullNorm) return true;

    switch (norm) {
      case 1:
        return (this.state.license.files.length > 0);
      case 2:
        return (this.state.fitness.files.length > 0);
      case 3:
        return (this.state.medical.files.length > 0);
      default:
        return true;
    }
  };

  handleInput = (e) => {
    this.validNormativityData(e.target.name);
    if (
      e.target.name === "medical" ||
      e.target.name === "fitness" ||
      e.target.name === "license"
    ) {
      if (
        e.target.value.files.filter((item) => {
          return item.size / 1024 / 1024 > MAXSIZE;
        }).length
      ) {
        this.setState({ showFilesSizeMessageError: true });

        e.target.value.files.pop();
      } else {
        this.setState({ [e.target.name]: e.target.value });
      }
    } else if (e.target.name !== "typeCategory") {

      let value = '';

      if (e.target.name === 'licenseNumber') {
        value = e.target.value.replace(/[^a-z0-9]/gi, '').toUpperCase();
      } else {
        value = e.target.value;
      }

      this.setState({ [e.target.name]: value }, () => {
        this.setState({
          isPasswordEqual: this.state.password === this.state.pwdConfirm,
        });
      });
    } else {
      this.updateLicenseData(e.target);
    }
  };

  validNormativityData = (name) => {
    if (
      name === "licenseNumber" ||
      name === "preventiveMedicine" ||
      name === "medical" ||
      name === "fitness" ||
      name === "license"
    ) {
      let data = this.state.normativityCountTotal;
      if (name === "licenseNumber") {
        name = "license";
      }
      if (name === "preventiveMedicine") {
        name = "fitness";
      }
      if (data.find((item) => item === name) === undefined) {
        data.push(name);
        this.setState({
          normativityCountTotal: data,
        });
      }
    }
  };

  fleetTagsToChips = () => {
    return this.props.fleetTagsCatalog.map((tag) => ({
      id: tag.id,
      title: tag.description,
      color: tag.color,
      isFull: this.state.fleetTags.find((ft) => ft === tag.id) ? true : false,
    }));
  };

  getOperatorData = (id) => {
    const birthDate = new Date(this.state.birthDate);
    const admissionDate = new Date(this.state.admissionDate);
    return {
      birth_date: this.state.birthDate 
        ? `${birthDate.getFullYear()}-${
            parseInt(birthDate.getMonth()) + 1
          }-${birthDate.getDate()}`
          : '',
      mobile_phone: this.state.celPhone,
      identification: this.state.ID,
      hire_date: this.state.admissionDate
        ? `${admissionDate.getFullYear()}-${
            parseInt(admissionDate.getMonth()) + 1
          }-${admissionDate.getDate()}`
        : '',
      r_control: this.state.rControl,
      licence_type: this.state.typeCategory,
      fit_to_operate: this.state.ableTo,
      user: id,
    };
  };

  handleTagChange = (e) => {
    let st = this.state.fleetTags.filter((ft) => ft !== e.target.id);

    if (!e.target.value) {
      this.handleInput({ target: { name: "fleetTags", value: [e.target.id] } });
      if (this.state.modeEdit) {
        const { id: operator_id } = this.state.operatorUser;
        const fleetId = this.props.fleet ? this.props.fleet.id : "";

        this.state.tagsData.forEach((item) => {
          this.props.deleteTagInOperador(operator_id, fleetId, item.id);
        });

        this.props
          .addTagToOperator(fleetId, operator_id, {
            data: operator_id,
            tags: e.target.id,
          })
          .then((fleet) => {
            let tagsData = this.state.tagsData.filter((tag) => {
              return tag.tags !== e.target.id;
            });

            tagsData.push(fleet.response);
            tagsData = tagsData.filter((item) => {
              return item.tags === e.target.id;
            });

            this.setState({
              tagsData: tagsData,
            });
          });
      }
    } else {
      if (this.state.modeEdit) {
        let tagToDelete = null;
        if (this.state.tagsData.length > 1) {
          (this.state.tagsData || []).forEach((item) => {
            if (item.tags.id === e.target.id || item.tags === e.target.id)
              tagToDelete = item.id;
          });

          if (tagToDelete) {
            const { id: operator_id } = this.state.operatorUser;
            const fleetId = this.props.fleet ? this.props.fleet.id : "";
            this.props.deleteTagInOperador(operator_id, fleetId, tagToDelete);
          }
          this.handleInput({ target: { name: "fleetTags", value: st } });
        }
      } else {
        this.handleInput({ target: { name: "fleetTags", value: st } });
      }
    }
  };

  handlePicture = () => {
    this.refs.fileUploader.value = "";
    this.refs.fileUploader.click();
  };

  // handleWSError = (wsError) => {
  //   if (wsError) {
  //     const errors = Object.values(wsError);
  //     let messageError = "";

  //     if (errors && errors.length) {
  //       errors.forEach((error, index) => {
  //         const tempMessage = error.map((message) => {
  //           return `${message}`;
  //         });

  //         if (index === error.length - 1) {
  //           messageError += tempMessage.concat(" ");
  //         } else {
  //           messageError += tempMessage;
  //         }
  //       });
  //     }

  //     return messageError;
  //   }

  //   return "Ocurrió un error, verifique sus datos";
  // };

  /////////////// Edit profile

  deleteImage = () => {
    const { id: operator_id } = this.state.operatorUser;
    const fleetId = this.props.fleet ? this.props.fleet.id : "";
    this.setState({ normativityUpdated: true });
    this.props.deleteNormativityImagesForOperator(
      fleetId,
      operator_id,
      this.state.normativityIdToRemove,
      this.state.fileIdToRemove
    );
  };

  deleteImageWithId = (normativity_id, file_id, counter) => {
    const { id: operator_id } = this.state.operatorUser;
    const fleet_id = this.props.fleet ? this.props.fleet.id : "";
    this.setState({ normativityUpdated: true });

    this.props.deleteFileV2({
      company_id: this.props.fleet?.company || 0,
      fleet_id,
      operator_id,
      normativity_id,
      file_id
    }, {
      onSuccess: () => {
        this.forceUpdate();
        counter.done();
      },
      onError: () => {
        this.forceUpdate();
        counter.done();
      }
    });
  };

  handleUpdateOperator = (userId) => {
    const userMobile = {
      first_name: this.state.name,
      last_name: this.state.lastname,
      username: this.state.userName,
      profile_image: this.state.profileImage,
      visible: 1,
    };

    this.fetchCounter += 1;
    this.forceUpdate();

    this.props.updateUserMobile(userId, userMobile).then((response) => {
      this.responseCounter += 1;
      this.forceUpdate();
    });

    const { id: operator_id, user } = this.state.operatorUser; 
    const fleetId = this.props.fleet ? this.props.fleet.id : "";

    if (this.state.dataId === null) {
      this.fetchCounter += 1;

      this.props
        .updateOperator(operator_id, fleetId, this.getOperatorData(user))
        .then((response) => {
          this.responseCounter += 1;
          this.forceUpdate();
        });
    }

    ///// Update License

    let licenseData = {
      type: 1,
      visible: true,
      code: this.state.licenseNumber,
      expedition_center: this.state.expeditionCenter,
      operator: operator_id,
      expedition_date: this.state.expeditionDate,
      expedition:
        this.state.license.expedition !== ""
          ? this.state.license.expedition
          : null,
      expiration:
        this.state.license.expiration !== ""
          ? this.state.license.expiration
          : null,
      medical_record_data: this.state.preventiveMedicine
    };

    if (this.state.normLicenseId !== -1) {
      this.fetchCounter += 1;
      this.props
        .updateNormativityDataForOperator(
          fleetId,
          operator_id,
          licenseData,
          this.state.normLicenseId
        )
        .then((normativityResponse) => {
          this.setState({ normativityUpdated: true });
          this.responseCounter += 1;
          this.forceUpdate();
        });

      if (
        Array.isArray(this.state.license.files) &&
        this.state.license.files.length > 0
      ) {
        this.state.license.files.forEach((file) => {
          if (file.idNormativityImage === undefined) {
            this.fetchCounter += 1;

            this.props
              .postUploadImageOperator(
                fleetId,
                operator_id,
                this.state.normLicenseId,
                file
              )
              .then((response) => {
                this.setState({ normativityUpdated: true });
                this.setState({
                  normativityCount: this.state.normativityCount + 1,
                });
                this.responseCounter += 1;
                this.forceUpdate();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      } else {
        let data = this.state.normativityCountTotal;
        if (data.find((item) => item === "license") !== undefined) {
          this.setState({
            normativityCount: this.state.normativityCount + 1,
          });
        }
      }
    } else {
      this.fetchCounter += 1;

      this.props
        .createNormativityDataForOperator(fleetId, operator_id, licenseData)
        .then((normativityResponse) => {
          this.setState({ normativityUpdated: true });
          this.responseCounter += 1;
          this.forceUpdate();

          // let error = null
          switch (normativityResponse.type) {
            case "FLEET_OPERATOR_NORMATIVITY_POST_SUCCESS":
              const normativityId = normativityResponse.response.id;

              if (
                Array.isArray(this.state.license.files) &&
                this.state.license.files.length > 0
              ) {
                this.state.license.files.forEach((file) => {
                  this.fetchCounter += 1;

                  this.props
                    .postUploadImageOperator(
                      fleetId,
                      operator_id,
                      normativityId,
                      file
                    )
                    .then((response) => {
                      this.setState({ normativityUpdated: true });
                      this.setState({
                        normativityCount: this.state.normativityCount + 1,
                      });
                      this.responseCounter += 1;
                      this.forceUpdate();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
              } else {
                let data = this.state.normativityCountTotal;
                if (data.find((item) => item === "license") !== undefined) {
                  this.setState({
                    normativityCount: this.state.normativityCount + 1,
                  });
                }
              }
              break;
            case "FLEET_OPERATOR_NORMATIVITY_POST_ERROR":
              break;
            default:
              break;
          }

          // if(error !== null){
          //   this.setNormativityError('license')
          // }
        });
    }

    /// Update Fitness

    let aptitudeData = {
      operator: operator_id,
      type: 2,
      expedition_center: this.state.lastMedicalTestType,
      expedition:
        this.state.fitness.expedition !== ""
          ? this.state.fitness.expedition
          : null,
      expiration:
        this.state.fitness.expiration !== ""
          ? this.state.fitness.expiration
          : null,
    };
    if (this.state.normFitnessId !== -1) {
      this.fetchCounter += 1;

      this.props
        .updateNormativityDataForOperator(
          fleetId,
          operator_id,
          aptitudeData,
          this.state.normFitnessId
        )
        .then((normativityResponse) => {
          this.setState({ normativityUpdated: true });
          this.responseCounter += 1;
          this.forceUpdate();
        });

      if (
        Array.isArray(this.state.fitness.files) &&
        this.state.fitness.files.length > 0
      ) {
        this.state.fitness.files.forEach((file) => {
          if (file.idNormativityImage === undefined) {
            this.fetchCounter += 1;

            this.props
              .postUploadImageOperator(
                fleetId,
                operator_id,
                this.state.normFitnessId,
                file
              )
              .then((response) => {
                this.setState({ normativityUpdated: true });

                this.setState({
                  normativityCount: this.state.normativityCount + 1,
                });

                this.responseCounter += 1;
                this.forceUpdate();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      } else {
        let data = this.state.normativityCountTotal;
        if (data.find((item) => item === "fitness") !== undefined) {
          this.setState({
            normativityCount: this.state.normativityCount + 1,
          });
        }
      }
    } else {
      this.fetchCounter += 1;

      this.props
        .createNormativityDataForOperator(fleetId, operator_id, aptitudeData)
        .then((normativityResponse) => {
          this.setState({ normativityUpdated: true });
          this.responseCounter += 1;
          this.forceUpdate();

          switch (normativityResponse.type) {
            case "FLEET_OPERATOR_NORMATIVITY_POST_SUCCESS":
              const normativityId = normativityResponse.response.id;

              if (
                Array.isArray(this.state.fitness.files) &&
                this.state.fitness.files.length > 0
              ) {
                this.state.fitness.files.forEach((file) => {
                  this.fetchCounter += 1;

                  this.props
                    .postUploadImageOperator(
                      fleetId,
                      operator_id,
                      normativityId,
                      file
                    )
                    .then((response) => {
                      this.setState({ normativityUpdated: true });

                      this.setState({
                        normativityCount: this.state.normativityCount + 1,
                      });
                      this.responseCounter += 1;
                      this.forceUpdate();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
              } else {
                let data = this.state.normativityCountTotal;
                if (data.find((item) => item === "fitness") !== undefined) {
                  this.setState({
                    normativityCount: this.state.normativityCount + 1,
                  });
                }
              }
              break;
            case "FLEET_OPERATOR_NORMATIVITY_POST_ERROR":
              break;
            default:
              break;
          }
        });
    }

    //// update Medical

    let medicalData = {
      operator: operator_id,
      type: 3,
      code: this.state.medical.number,
      expedition:
        this.state.medical.expedition !== ""
          ? this.state.medical.expedition
          : null,
      expiration:
        this.state.medical.expedition !== ""
          ? this.state.medical.expedition
          : null,
    };
    if (this.state.normMedicalId !== -1) {
      this.fetchCounter += 1;

      this.props
        .updateNormativityDataForOperator(
          fleetId,
          operator_id,
          medicalData,
          this.state.normMedicalId
        )
        .then((normativityResponse) => {
          this.setState({ normativityUpdated: true });
          this.responseCounter += 1;
          this.forceUpdate();
        });

      if (
        Array.isArray(this.state.medical.files) &&
        this.state.medical.files.length > 0
      ) {
        this.state.medical.files.forEach((file) => {
          if (file.idNormativityImage === undefined) {
            this.fetchCounter += 1;

            this.props
              .postUploadImageOperator(
                fleetId,
                operator_id,
                this.state.normMedicalId,
                file
              )
              .then((response) => {
                this.setState({ normativityUpdated: true });

                this.setState({
                  normativityCount: this.state.normativityCount + 1,
                });

                this.responseCounter += 1;
                this.forceUpdate();
              });
          }
        });
      } else {
        let data = this.state.normativityCountTotal;
        if (data.find((item) => item === "medical") !== undefined) {
          this.setState({
            normativityCount: this.state.normativityCount + 1,
          });
        }
      }
    } else {
      this.fetchCounter += 1;

      this.props
        .createNormativityDataForOperator(fleetId, operator_id, medicalData)
        .then((normativityResponse) => {
          this.setState({ normativityUpdated: true });
          this.responseCounter += 1;
          this.forceUpdate();

          switch (normativityResponse.type) {
            case "FLEET_OPERATOR_NORMATIVITY_POST_SUCCESS":
              const normativityId = normativityResponse.response.id;

              if (
                Array.isArray(this.state.medical.files) &&
                this.state.medical.files.length > 0
              ) {
                this.state.medical.files.forEach((file) => {
                  this.fetchCounter += 1;

                  this.props
                    .postUploadImageOperator(
                      fleetId,
                      operator_id,
                      normativityId,
                      file
                    )
                    .then((response) => {
                      this.setState({ normativityUpdated: true });

                      this.setState({
                        normativityCount: this.state.normativityCount + 1,
                      });

                      this.responseCounter += 1;
                      this.forceUpdate();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
              } else {
                let data = this.state.normativityCountTotal;
                if (data.find((item) => item === "medical") !== undefined) {
                  this.setState({
                    normativityCount: this.state.normativityCount + 1,
                  });
                }
              }
              break;
            case "FLEET_OPERATOR_NORMATIVITY_POST_ERROR":
              break;
            default:
              break;
          }
        });
    }
  };

  getNormPropName = (index) => {
    switch (index) {
      case 0:
        return "medical";
      case 1:
        return "fitness";
      case 2:
        return "license";
      default:
        return ""
    }
  }

  /////////////// Save profile

  validateNormativities = () => {
    if (!this.isEditMode()) {
      this.handleDeleteDocs();
      return
    }

    if (this.didNormativitiesChange() && this.state.companiesLinked > 1) {
      this.setState({normativitiesChangedConfirmation: true})
      return;
    }

    this.handleDeleteDocs();
  }

  handleDeleteImages = (counter) => {

    if (this.state.normativityImagesToDelete['license'].length > 0) {
      let normativity_id = this.state.license.id;

      this.state.normativityImagesToDelete['license'].map( item => {
        this.deleteImageWithId(normativity_id, item.idNormativityImage, counter);
      });
    }

    if (this.state.normativityImagesToDelete['fitness'].length > 0) {
      let normativity_id = this.state.fitness.id;

      this.state.normativityImagesToDelete['fitness'].map( item => {
        this.deleteImageWithId(normativity_id, item.idNormativityImage, counter);
      });
    }

    if (this.state.normativityImagesToDelete['medical'].length > 0) {
      let normativity_id = this.state.medical.id;

      this.state.normativityImagesToDelete['medical'].map( item => {
        this.deleteImageWithId(normativity_id, item.idNormativityImage, counter);
      });
    }
  
  }

  handleDeleteDocs = () => {
    const counter = new SaveItemCounter(() => {
      this.handleSaveOperator();
    });

    let imageCounter = 0;

    if (this.state.normativityImagesToDelete['license'].length > 0) {
      imageCounter += this.state.normativityImagesToDelete['license'].length;
    }

    if (this.state.normativityImagesToDelete['fitness'].length > 0) {
      imageCounter += this.state.normativityImagesToDelete['fitness'].length;
    }

    if (this.state.normativityImagesToDelete['medical'].length > 0) {
      imageCounter += this.state.normativityImagesToDelete['medical'].length;
    }

    if (imageCounter > 0) {
      Array.from(Array(imageCounter).keys()).forEach( sI => {
        counter.add();
      });

      this.handleDeleteImages(counter);
    } else {
      this.handleSaveOperator();
    }
  }

  handleSaveOperator = () => {
    this.fetchCounter += 1;
    this.forceUpdate();

    const birthDate = new Date(this.state.birthDate);
    const admissionDate = new Date(this.state.admissionDate);

    var taxInfoData = {
      zip_code: this.state.postalCodeId,
      fiscal_number: this.state.rfc,
    };

    if (parseInt(this.state.municipality) > 0) {
      taxInfoData.city = parseInt(this.state.municipality);
    }

    if (parseInt(this.state.country) > 0) {
      taxInfoData.country = parseInt(this.state.country);
    }

    if (this.state.numExt !== '') { 
      taxInfoData.exterior_number = this.state.numExt;
    } else {
      taxInfoData.exterior_number = '';
    }

    if (this.state.numInt !== '') { 
      taxInfoData.interior_number = this.state.numInt;
    } else {
      taxInfoData.interior_number = '';
    }

    if (parseInt(this.state.locality) > 0) {
      taxInfoData.locality = parseInt(this.state.locality);
    }

    if (parseInt(this.state.stateOperator) > 0) {
      taxInfoData.state = parseInt(this.state.stateOperator);
    }

    if (this.state.suburb !== 0) {
      taxInfoData.suburb = this.state.suburb;
    }

    if (this.state.street !== '') { 
      taxInfoData.street = this.state.street;
    } else {
      taxInfoData.street = '';
    }

    if (this.state.reference !== '') { 
      taxInfoData.reference = this.state.reference;
    }

    var tagsToSend = [];
    var tagsToValidate = (this.state.fleetTags && this.state.fleetTags.length > 0) ? this.state.fleetTags : [];

    for (var i = 0; i < tagsToValidate.length; i++) {
      if (!tagsToSend.includes(tagsToValidate[i])) {
        tagsToSend = tagsToSend.concat(tagsToValidate[i]);
      }
    }

    var dataJson = {
      first_name: this.state.name,
      last_name: this.state.lastname,
      username: this.state.licenseNumber,
      operator: {
        birth_date: this.state.birthDate
          ? `${birthDate.getFullYear()}-${
              parseInt(birthDate.getMonth()) + 1
            }-${birthDate.getDate()}`
          : null,
        mobile_phone: this.state.celPhone,
        identification: this.state.ID,
        curp: this.state.curp,
        hire_date: this.state.admissionDate
          ? `${admissionDate.getFullYear()}-${
            parseInt(admissionDate.getMonth()) + 1
          }-${admissionDate.getDate()}`
          : null,
        //r_control: this.state.rControl,
        licence_type: this.state.typeCategory,
        fit_to_operate: this.state.ableTo,
      },
      tag_list: tagsToSend.map(t => ({tags: t})),
      normativity_list: this.generateNormativity().map((norm, i) => {
        let normData = this.state[this.getNormPropName(i)] || {};
        let extraData = {};

        normData.expedition_center = '';
        normData.expedition_date = '';

        if(norm.description.includes('icencia')){
          normData.expedition_center = this.state.expeditionCenter;
          normData.expedition_date = this.state.expeditionDate;
          normData.number = this.state.licenseNumber;
        }
        if (norm.description.includes('MSS')) {
          normData.expedition_date = normData.expedition;
        }

        let jsonToSave = {
          code: normData.number ? normData.number : '',
          type: norm.id,
          is_considered: normData.isConsidered,
          expedition: normData.expedition && normData.expedition !== null ? normData.expedition : null,
          expiration: normData.expiration && normData.expiration !== null ? normData.expiration : null,
          data: extraData,
          expedition_center: normData.expedition_center,
          expedition_date: normData.expedition_date
        }

        if(norm.description.includes('icencia')){
          jsonToSave.medical_record_data = this.state.preventiveMedicine;
        }

        return jsonToSave;
      })
    }

    if (this.state.rfc !== '') {
      dataJson.tax_info = taxInfoData;
    }

    if (!this.state.modeEdit) {
      dataJson.password = this.state.password;
    }

    let newData = {
      data: JSON.stringify(dataJson),
      company_id: this.props.fleet?.company || 0,
      fleet_id: this.props.fleet?.id || 0,
    }

    if (this.state.modeEdit) {

      newData.data_id = this.props.match?.params?.operatorId || 0;

      this.generateNormativity().forEach((norm, i) => {
        let normData = this.state[this.getNormPropName(i)] || {};
        normData.files.filter(f => !f.idNormativityImage).forEach((f, fI) => {
          if (f.idNormativityImage === undefined) {
            newData[''.concat(norm.id, '_', fI + 1)] = f;
          }
        })
      });
    } else {
      this.generateNormativity().forEach((norm, i) => {
        let normData = this.state[this.getNormPropName(i)] || {};
        normData.files.forEach((f, fI) => {
          newData[''.concat(norm.id, '_', fI + 1)] = f;
        })
      });
    }

    if (this.state.profileImage) {
      newData.profile_image = this.state.profileImage
    }  

    if (this.state.modeEdit) {
      this.props.updateFleetDataV2(
        newData, {
        isForm: true,
        onSuccess: () => {

          if (!this.didNormativitiesChange()) {
            this.responseCounter += 1;
            this.forceUpdate();
            return;
          }

          this.fetchCounter += 2; // se aumenta el contador por el clonando de info de normatividades y calculo de estatus de normatividades
          this.responseCounter += 1;

          this.props.cloningFleetDataV2({
            company_id: this.props.fleet?.company || 0,
            fleet_id: this.props.fleet?.id || 0,
            data_id: parseInt(this.props.match?.params?.operatorId || 0)
          }, {
            onSuccess: () => {
              this.responseCounter += 1;
              this.forceUpdate();

              this.callUpdateStatus();
            },
            onError: () => {
              this.responseCounter += 1;
              this.forceUpdate();

              this.callUpdateStatus();
            }
          });

        },
        onError: (e) => {
          let errors = e.response?.data?.errors || [];
          var errorsToShow = [];

          if (errors.length > 0) {
            if (typeof errors[0] === 'string') {
              errorsToShow = errors;
            } else {
              errorsToShow = errors[0];
            }
          } else {
            errorsToShow = ['Ocurrió un error al guardar, verifica los datos'];
          }

          this.setState(
            {
              showError: true,
              msgError: handleWSErrorWithKeys(errorsToShow),
              showRegisterError: false,
            }, () => {
              this.fetchCounter = 0;
              this.responseCounter = 0;
              this.forceUpdate();
            }
          )
        }
      });
    } else {
      this.props.createFleetDataV2(
        newData, {
        isForm: true,
        onSuccess: () => {
          this.responseCounter += 1;
          this.forceUpdate();
        },
        onError: (e) => {

          let errors = e.response?.data?.errors || [];
          var errorsToShow = [];

          if (errors.length > 0) {
            if (typeof errors[0] === 'string') {
              errorsToShow = errors;
            } else {
              errorsToShow = errors[0];
            }
          } else {
            errorsToShow = ['Ocurrió un error al guardar, verifica los datos'];
          }

          this.setState(
            {
              showError: true,
              msgError: handleWSErrorWithKeys(errorsToShow),
              showRegisterError: false,
            }, () => {
              this.fetchCounter = 0;
              this.responseCounter = 0;
              this.forceUpdate();
            }
          )
        }
      });
    }

    // this.props.createUserMobile(userMobile).then((response) => {
    //   let error = null;
    //   switch (response.type) {
    //     case CREATE_USER_RESPONSE:
    //       const userId = response.data.id;

    //       this.registerOperator(userId);

    //       this.responseCounter += 1;
    //       this.forceUpdate();

    //       break;
    //     case CREATE_USER_ERROR:
    //       error = "Ocurrió un error al registrar, verifica los datos";
    //       break;
    //     default:
    //       error = "Ocurrió un error al registrar, verifica los datos";
    //       break;
    //   }

    //   if (error !== null) {
    //     this.setState(
    //       {
    //         // showError: true,
    //         // msgError: error,

    //         showError: true,
    //         messageError: this.handleWSError(response.data),
    //       },
    //       () => {
    //         this.responseCounter = 0;
    //         this.fetchCounter = 0;
    //         this.forceUpdate();
    //       }
    //     );
    //   }
    // });

  };

  callUpdateStatus = () => {
    this.props.updateStatusFleetDataV2({
      company_id: this.props.fleet?.company || 0,
      fleet_id: this.props.fleet?.id || 0,
      data_id: parseInt(this.props.match?.params?.operatorId || 0)
    }, {
      onSuccess: () => {
        this.responseCounter += 1;
        this.forceUpdate();
      },
      onError: () => {
        this.responseCounter += 1;
        this.forceUpdate();
      }
    });
  }

  registerOperator = (userId) => {
    const fleetId = this.props.fleet ? this.props.fleet.id : "";

    this.fetchCounter += 1;
    this.props
      .createOperator(fleetId, this.getOperatorData(userId))
      .then((operatorResponse) => {
        let error = null;
        switch (operatorResponse.type) {
          case "FLEET_OPERATOR_POST_SUCCESS":
            this.responseCounter += 1;
            this.setState({
              operatorUser: operatorResponse.response,
            });
            this.forceUpdate();

            const operator_id = operatorResponse.response.id;

            this.state.fleetTags.forEach((item) => {
              this.props.addTagToOperator(fleetId, operator_id, {
                data: operator_id,
                tags: item,
              });
            });

            let licenseData = {
              type: 1,
              visible: true,
              code: this.state.licenseNumber,
              expedition_center: this.state.expeditionCenter,
              operator: operator_id,
              expedition_date: this.state.expeditionDate,
              expedition:
                this.state.license.expedition !== ""
                  ? this.state.license.expedition
                  : null,
              expiration:
                this.state.license.expiration !== ""
                  ? this.state.license.expiration
                  : null,
              medical_record_data: this.state.preventiveMedicine
            };

            this.fetchCounter += 1;

            this.props
              .createNormativityDataForOperator(
                fleetId,
                operator_id,
                licenseData
              )
              .then((normativityResponse) => {
                this.setState({ normativityUpdated: true });
                this.responseCounter += 1;
                this.forceUpdate();

                // let error = null
                switch (normativityResponse.type) {
                  case "FLEET_OPERATOR_NORMATIVITY_POST_SUCCESS":
                    const normativityId = normativityResponse.response.id;

                    if (
                      Array.isArray(this.state.license.files) &&
                      this.state.license.files.length > 0
                    ) {
                      this.state.license.files.forEach((file) => {
                        this.fetchCounter += 1;

                        this.props
                          .postUploadImageOperator(
                            fleetId,
                            operator_id,
                            normativityId,
                            file
                          )
                          .then((response) => {
                            this.setState({ normativityUpdated: true });
                            this.setState({
                              normativityCount: this.state.normativityCount + 1,
                            });
                            this.responseCounter += 1;
                            this.forceUpdate();
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      });
                    } else {
                      this.setState({
                        normativityCount: this.state.normativityCount + 1,
                      });
                    }
                    break;
                  case "FLEET_OPERATOR_NORMATIVITY_POST_ERROR":
                    break;
                  default:
                    break;
                }

                // if(error !== null){
                //   this.setNormativityError('license')
                // }
              });

            //////////////////////////////////////////

            let aptitudeData = {
              operator: operator_id,
              type: 2,
              expedition_center: this.state.lastMedicalTestType,
              expedition:
                this.state.fitness.expedition !== ""
                  ? this.state.fitness.expedition
                  : null,
              expiration:
                this.state.fitness.expiration !== ""
                  ? this.state.fitness.expiration
                  : null,
            };

            this.fetchCounter += 1;

            this.props
              .createNormativityDataForOperator(
                fleetId,
                operator_id,
                aptitudeData
              )
              .then((normativityResponse) => {
                this.setState({ normativityUpdated: true });
                this.responseCounter += 1;
                this.forceUpdate();

                switch (normativityResponse.type) {
                  case "FLEET_OPERATOR_NORMATIVITY_POST_SUCCESS":
                    const normativityId = normativityResponse.response.id;

                    if (
                      Array.isArray(this.state.fitness.files) &&
                      this.state.fitness.files.length > 0
                    ) {
                      this.state.fitness.files.forEach((file) => {
                        this.fetchCounter += 1;

                        this.props
                          .postUploadImageOperator(
                            fleetId,
                            operator_id,
                            normativityId,
                            file
                          )
                          .then((response) => {
                            this.setState({ normativityUpdated: true });
                            this.setState({
                              normativityCount: this.state.normativityCount + 1,
                            });
                            this.responseCounter += 1;
                            this.forceUpdate();
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      });
                    } else {
                      this.setState({
                        normativityCount: this.state.normativityCount + 1,
                      });
                    }
                    break;
                  case "FLEET_OPERATOR_NORMATIVITY_POST_ERROR":
                    break;
                  default:
                    break;
                }
              });

            ////////////////////////////////

            let medicalData = {
              operator: operator_id,
              type: 3,
              code: this.state.medical.number,
              expedition:
                this.state.medical.expedition !== ""
                  ? this.state.medical.expedition
                  : null,
              expiration:
                this.state.medical.expedition !== ""
                  ? this.state.medical.expedition
                  : null,
            };

            this.fetchCounter += 1;

            this.props
              .createNormativityDataForOperator(
                fleetId,
                operator_id,
                medicalData
              )
              .then((normativityResponse) => {
                this.setState({ normativityUpdated: true });
                this.responseCounter += 1;
                this.forceUpdate();

                switch (normativityResponse.type) {
                  case "FLEET_OPERATOR_NORMATIVITY_POST_SUCCESS":
                    const normativityId = normativityResponse.response.id;

                    if (
                      Array.isArray(this.state.medical.files) &&
                      this.state.medical.files.length > 0
                    ) {
                      this.state.medical.files.forEach((file) => {
                        this.fetchCounter += 1;

                        this.props
                          .postUploadImageOperator(
                            fleetId,
                            operator_id,
                            normativityId,
                            file
                          )
                          .then((response) => {
                            this.setState({ normativityUpdated: true });
                            this.setState({
                              normativityCount: this.state.normativityCount + 1,
                            });
                            this.responseCounter += 1;
                            this.forceUpdate();
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      });
                    } else {
                      let data = this.state.normativityCountTotal;
                      if (
                        data.find((item) => item === "medical") !== undefined
                      ) {
                        this.setState({
                          normativityCount: this.state.normativityCount + 1,
                        });
                      }
                    }
                    break;
                  case "FLEET_OPERATOR_NORMATIVITY_POST_ERROR":
                    break;
                  default:
                    break;
                }
              });

            ////////////////////////////////

            break;
          case "FLEET_OPERATOR_POST_ERROR":
            error = "Ocurrió un error al registrar, verifica los datos";
            break;
          default:
            error = "Ocurrió un error al registrar, verifica los datos";
            break;
        }

        if (error !== null) {
          this.setState(
            {
              showError: true,
              msgError: handleWSError(operatorResponse.error || null),
              modeEdit: true,  
              dataId: userId,
            },
            () => {
              this.fetchCounter = 0;
              this.responseCounter = 0;
              this.forceUpdate();
            }
          );
        }
      });
  };

  handleValidate = (response, msg, field) => {
    switch (response.type) {
      case "VALIDATE_USERNAME_RESPONSE":
      case "FLEET_OPERATOR_VALIDATIONS_ID_SUCCESS":
      case "FLEET_OPERATOR_VALIDATIONS_LICENSE_SUCCESS":
      case "FLEET_OPERATOR_VALIDATIONS_APTITUDE_SUCCESS":
      case "FLEET_OPERATOR_VALIDATIONS_SECURITY_SUCCESS":
        this.setDupliedField(field, false);
        break;
      case "VALIDATE_USERNAME_ERROR":
      case "FLEET_OPERATOR_VALIDATIONS_ID_ERROR":
      case "FLEET_OPERATOR_VALIDATIONS_LICENSE_ERROR":
      case "FLEET_OPERATOR_VALIDATIONS_APTITUDE_ERROR":
      case "FLEET_OPERATOR_VALIDATIONS_SECURITY_ERROR":
        this.setDupliedField(field, true);
        break;
      default:
        this.setDupliedField(field, true);
        break;
    }
  };

  setDupliedField = (field, boolean) => {
    switch (field) {
      case "username":
        this.setState({ dupliedUsername: boolean });
        break;
      case "id":
        this.setState({ dupliedId: boolean });
        break;
      case "license":
        this.setState({ dupliedLicense: boolean });
        break;
      case "aptitude":
        this.setState({ dupliedAptitude: boolean });
        break;
      case "security":
        this.setState({ dupliedSecurity: boolean });
        break;
      default:
        break;
    }
  };

  dragAndDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let dt = e.dataTransfer;
    let files = dt.files;

    let pictureFrame = document.getElementsByClassName("pictureFrame");
    let pictureButton = document.getElementsByClassName("btnPicture");

    switch (e.type) {
      case "dragenter":
      case "dragover":
        pictureFrame[0].style.transform = "scale(1.1, 1.1)";

        pictureButton[0].style.color = "#3D77F7";
        pictureButton[0].style.border = "2px solid #ffffff";
        pictureButton[0].style.backgroundColor = "#ffffff";
        break;
      default:
        pictureFrame[0].style.transform = "scale(1.0, 1.0)";

        pictureButton[0].style.color = "rgb(232, 240, 255)";
        pictureButton[0].style.border = "2px solid white";
        pictureButton[0].style.background = "none";

        const profileImage = files[0];

        var reader = new FileReader();
        reader.addEventListener("load", () => {
          this.setState({
            imageToCrop: reader.result,
            editImage: true,
          });
        });
        reader.readAsDataURL(profileImage);
        break;
    }
  };

  calculateAge = (birthDate) => {
    let age = "";
    if (String(birthDate) !== "") {
      const ageDifMs = Date.now() - new Date(birthDate);
      if (ageDifMs > 0) {
        const ageDate = new Date(ageDifMs);
        age = Math.abs(ageDate.getUTCFullYear() - 1970);
        if (age < 1) {
          age = "";
        }
      }
    }

    return age;
  };

  savingDataText = () => {
    if (this.state.modeEdit) {
      return this.fetchCounter > 0 ? "Guardando..." : "Guardar";
    } else {
      return this.fetchCounter > 0 ? "Registrando..." : "Registrar";
    }
  };

  generateNormativity = () => {
    let results = [
      {
        id: 3,
        description: "Constancia de registro de IMSS",
        documentType: DocumentTypes.file,
        label: "N° ",
        name: "medical",
      },
      {
        id: 2,
        description: "Constancia de aptitud psicofisica",
        documentType: DocumentTypes.file,
        label: "N° ",
        name: "fitness",
      },
      {
        id: 1,
        description: "Licencia de conducir (ambos lados)",
        documentType: DocumentTypes.file,
        label: "N° ",
        name: "license",
      },
    ];

    return results;
  };

  validateTagData = () => {
    if (this.state.fleetTags.length === 0) {
      return false;
    }
    return true;
  };
  getTypeCategoryCatalog = () => {
    return this.props.typeCategoryCatalog.map((item) => {
      return {
        id: item.abbreviation,
        description: item.description,
      };
    });
  };

  updateLicenseData = (data) => {
    let license =
      this.state.typeCategory !== "" ? this.state.typeCategory.split(",") : [];
    if (data.checked) {
      license.push(data.value);
    } else {
      license = license.filter((item) => item !== data.value);
    }
    license.sort();
    license = license.join(",");
    this.setState({ [data.name]: license });
  };

  handleAddress = (event) => {
    let property = event.target.name;
    let value = event.target.value;
    if (property === "idOrbi") {
      value = String(value).replace(/\s/g, '_')
      this.setState({ [property]: value })
    }else if (property === "postalCode") {

      this.setState({[property]: value}, () => {
        if (this.state.country > 0) {
          clearTimeout(typingTimer);
          typingTimer = setTimeout(this.getZipCodeInfo, 1000);
        }
      })

    } else if(property === "country"){
      this.setState({[property]: value}, () => {
        if(this.state.postalCode) this.getZipCodeInfo();
      }) 

      if (this.state.postalCode !== "") {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(this.getZipCodeInfo, 1000);
      }
    } else {
      this.setState({ [property]: value })
    }
  }

  getZipCodeInfoInit = () => {
    this.props.checkZipCode({
      country: this.state.country,
      cp: this.state.postalCode,
    }, {
      prefix: 'orbisat.',
      onSuccess: (result) => {
        if(result.length){
          // let countryOptions = [{id: result[0].pais_id, description: result[0].pais}];
          let neighborhoodOptions = result.map(r => ({id: r.colonia_id, description: r.colonia}))
          let stateOptions = [{id: result[0].estado_id, description: result[0].estado}];
          let cityOptions = [{id: result[0].municipio_id, description: result[0].municipio}];
          let localityOptions = [{id: result[0].localidad_id, description: result[0].localidad}];

          if (this.state.suburb === 0) {
            this.state.suburb = result.length > 1 ? 0 : result[0].colonia_id;
          }

          this.setState({
            // countryOptions,
            neighborhoodOptions,
            stateOptions,
            cityOptions,
            localityOptions, 

            // country: result[0].pais_id,
            stateOperator: result[0].estado_id,
            municipality: result[0].municipio_id,
            locality: result[0].localidad_id,
            postalCodeId: result[0].cp_id,
          })
        } else {
          this.setState({
            // countryOptions: [],
            neighborhoodOptions: [],
            stateOptions: [],
            cityOptions: [],
            localityOptions: [],
            // country: 0,
            neighborhood: 0,
            state: 0,
            city: 0,
            locality: 0,
            postalCodeId: 0
          })
        }
      }
    })
  }

  getZipCodeInfo = () => {
    this.props.checkZipCode({
      country: this.state.country,
      cp: this.state.postalCode,
    }, {
      prefix: 'orbisat.',
      onSuccess: (result) => {
        if(result.length){
          // let countryOptions = [{id: result[0].pais_id, description: result[0].pais}];
          let neighborhoodOptions = result.map(r => ({id: r.colonia_id, description: r.colonia}))
          let stateOptions = [{id: result[0].estado_id, description: result[0].estado}];
          let cityOptions = [{id: result[0].municipio_id, description: result[0].municipio}];
          let localityOptions = [{id: result[0].localidad_id, description: result[0].localidad}];

          this.setState({
            // countryOptions,
            neighborhoodOptions,
            stateOptions,
            cityOptions,
            localityOptions, 

            // country: result[0].pais_id,
            suburb: result.length > 1 ? 0 : result[0].colonia_id,
            stateOperator: result[0].estado_id,
            municipality: result[0].municipio_id,
            locality: result[0].localidad_id,
            postalCodeId: result[0].cp_id,
          })
        } else {
          this.setState({
            // countryOptions: [],
            neighborhoodOptions: [],
            stateOptions: [],
            cityOptions: [],
            localityOptions: [],
            // country: 0,
            neighborhood: 0,
            state: 0,
            city: 0,
            locality: 0,
            postalCodeId: 0
          })
        }
      }
    })
  }

  isEditMode = () => {
    return(
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.operatorId
    );
  }

  didNormativitiesChange = () => {

    let has_new_medical_docs = false;
    let medical_files = ( this.state.medical?.files || []);

    medical_files.map( item => {
      if (!('idNormativityImage' in item)) {
        has_new_medical_docs = true;
      }
    });

    let has_new_fitness_docs = false;
    let fitness_files = ( this.state.fitness?.files || []);

    fitness_files.map( item => {
      if (!('idNormativityImage' in item)) {
        has_new_fitness_docs = true;
      }
    });

    let has_new_license_docs = false;
    let license_files = ( this.state.license?.files || []);

    license_files.map( item => {
      if (!('idNormativityImage' in item)) {
        has_new_license_docs = true;
      }
    });

    return (
      !(
        this.state.dataBackup.licenseExpedition === this.state.license.expedition &&
        this.state.dataBackup.licenseExpiration === this.state.license.expiration &&
        this.state.dataBackup.licenseTypeCategory === this.state.typeCategory &&
        this.state.dataBackup.licenseExpeditionCenter === this.state.expeditionCenter &&
        this.state.dataBackup.licenseExpeditionDate === this.state.expeditionDate &&
        this.state.dataBackup.licenseAbleTo === this.state.ableTo &&
        this.state.dataBackup.aptitudeExpedition === this.state.fitness.expedition &&
        this.state.dataBackup.aptitudeExpiration === this.state.fitness.expiration &&
        this.state.normativityImagesToDelete['license'].length === 0 &&
        // this.state.normativityImagesToDelete['medical'].length === 0 && 
        this.state.normativityImagesToDelete['fitness'].length === 0 &&
        // !has_new_medical_docs &&
        !has_new_fitness_docs &&
        !has_new_license_docs &&
        this.state.dataBackup.preventiveMedicine === this.state.preventiveMedicine
      )
    );
  }

  render() {
    return (
      <div className="fleet-operator-view view">
        <input
          accept="image/*"
          type="file"
          id="file"
          ref="fileUploader"
          style={{ display: "none" }}
          onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();
            var reader = new FileReader();

            reader.addEventListener("load", () => {
              this.setState({
                imageToCrop: reader.result,
                editImage: true,
              });
            });

            reader.readAsDataURL(event.target.files[0]);
          }}
        />

        <div className="title">
          { this.isEditMode()
            ? "Información de operador"
            : "Registro de operador"}
        </div>
        <Panel className={"license ".concat((this.props.match.params && this.props.match.params.operatorId) ? "hidden" : "")} title="Creación de cuenta" number="1.1">
          <div className="general-data">
            <TextInput
              label="Número de licencia"
              name="licenseNumber"
              value={this.state.licenseNumber}
              className="item"
              onChange={(e) => {
                if (this.state.dupliedLicense)
                  this.setState({ dupliedLicense: false });
                this.handleInput(e);
              }}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
              errors={
                this.state.dupliedLicense
                  ? [`La licencia "${this.state.licenseNumber}" esta en uso`]
                  : []
              }
              handleBlur={(e) => {
                if (String(this.state.ownLicense) !== String(e.target.value)) {
                  this.props
                    .postValidateLicense(this.props.fleet.id, e.target.value)
                    .then((response) => {
                      this.handleValidate(
                        response,
                        `La licencia "${this.state.licenseNumber}"`,
                        "license"
                      );
                    });
                }
              }}
            />
            <TextInput
              type={this.state.pwdType ? "password" : "text"}
              label="Contraseña" 
              name="password"
              value={this.state.password}
              className="item"
              onChange={this.handleInput}
              disabled={this.state.modeEdit}
              suffix={{
                element: (
                  <div
                    onClick={() => {
                      this.handleInput({
                        target: { name: "pwdType", value: !this.state.pwdType },
                      });
                    }}
                  >
                    <SVGIcon name={icons.passwordToggle} fill="#AFC8FF" />
                  </div>
                ),
                className: "",
              }}
              validate={[validators.required, validators.password]}
              onFocused={this.state.onFocused}
            />
            <TextInput
              type={this.state.pwdConfType ? "password" : "error"}
              label="Confirmar contraseña"
              name="pwdConfirm"
              value={this.state.pwdConfirm}
              className="item"
              onChange={this.handleInput}
              errors={
                !this.state.isPasswordEqual && this.state.pwdConfirm.length > 0
                  ? ["Las contraseñas deben coincidir"]
                  : []
              }
              disabled={this.state.modeEdit}
              suffix={{
                element: (
                  <div
                    onClick={() => {
                      this.handleInput({
                        target: {
                          name: "pwdConfType",
                          value: !this.state.pwdConfType,
                        },
                      });
                    }}
                  >
                    <SVGIcon name={icons.passwordToggle} fill="#AFC8FF" />
                  </div>
                ),
                className: "",
              }}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
            />
          </div>
        </Panel>
        <Panel title="Datos generales" number="1.2">
          <div className="profile-operator-image">
            <InputProfileImage2
              dragAndDrop={this.dragAndDrop}
              handlePicture={this.handlePicture}
              profileImage={this.state.profileImage}
              caption="Dale clic al cuadro para agregar tu foto o bien, arrástrala desde tu computadora:"
            />
          </div>
          <div className="general-data">
            <TextInput
              label="Nombre del operador (Máx 39 caracteres nombre completo)"
              name="name"
              value={this.state.name}
              className="item third"
              onChange={this.handleInput}
              validate={[validators.required, "maxLength"]}
              validationParam={39-this.state.lastname.length}
              onFocused={this.state.onFocused}
            />
            <TextInput
              label="Apellidos del operador (Máx 39 caracteres nombre completo)"
              name="lastname"
              value={this.state.lastname}
              className="item third"
              onChange={this.handleInput}
              validate={[validators.required, "maxLength"]}
              validationParam={39-this.state.name.length}
              onFocused={this.state.onFocused}
            />
            <TextInput
              type="date"
              label="Fecha de nacimiento"
              name="birthDate"
              value={this.state.birthDate}
              className="item third"
              onChange={this.handleInput}
              suffix={{
                element: <SVGIcon name={icons.calendar} fill="#AFC8FF" />,
                className: "",
              }}
              // validate={[validators.required]}
              onFocused={this.state.onFocused}
              format={"dd/MMM/yyyy"}
            />
            <TextInput
              label="Edad"
              name="age"
              value={String(this.calculateAge(this.state.birthDate))}
              className="item third"
              disabled={true}
            />
            {/* <TextInput
              type="date"
              label="Fecha de ingreso"
              name="admissionDate"
              value={this.state.admissionDate}
              className="item third"
              onChange={this.handleInput}
              suffix={{
                element: <SVGIcon name={icons.calendar} fill="#AFC8FF" />,
                className: "",
              }}
              // validate={[validators.required]}
              onFocused={this.state.onFocused}
              format={"dd/MMM/yyyy"}
            /> */}
            <TextInput
              label="INE ó DNI (ID)"
              name="ID"
              value={this.state.ID}
              className="item"
              onChange={this.handleInput}
              onFocused={this.state.onFocused}
            />
            <TextInput
              label="CURP"
              name="curp"
              value={this.state.curp}
              className="item third"
              onChange={this.handleInput}
              validate={["maxLength"]}
              validationParam={30}
              onFocused={this.state.onFocused}
            />
          </div>
          <div className="fleet-tags" style={{ marginTop: "15px" }}>
            <ChipContainer
              items={this.fleetTagsToChips()}
              title="Etiquetas"
              onChange={this.handleTagChange}
              name="fleetTags"
            />
          </div>
        </Panel>
        <Panel className="license" title="Datos de licencia" number="2.0">
          <div className="license-data">
            {
              this.isEditMode() &&
              <TextInput
                label="Licencia "
                name="license_info"
                value={this.state.licenseNumber}
                className="item"
                disabled
                onChange={(e) => {}}
              />
            }
            <SelectMultipleInput
              title={"Categoría"}
              items={this.getTypeCategoryCatalog()}
              // onChange={this.handleChange}
              onChange={this.handleInput}
              name="typeCategory"
              className="item"
              value={this.state.typeCategory}
            />
            <SelectInput
              label="Centro de expedición SCT"
              name="expeditionCenter"
              value={this.state.expeditionCenter}
              className="item"
              onChange={this.handleInput}
              items={this.props.expeditionCenterCatalog}
            />
            <TextInput
              type="date"
              label="Antigüedad desde"
              name="expeditionDate"
              value={this.state.expeditionDate}
              className="item third"
              onChange={this.handleInput}
              suffix={{
                element: <SVGIcon name={icons.calendar} fill="#AFC8FF" />,
                className: "",
              }}
              format={"dd/MMM/yyyy"}
              onFocused={this.state.onFocused}
              validate={["lessThanDate"]}
            />
            <TextInput
              label="Expediente médico"
              name="preventiveMedicine"
              value={this.state.preventiveMedicine}
              className="item"
              onChange={this.handleInput}
            />
            <SelectInput
              label="Apto para operar"
              name="ableTo"
              value={this.state.ableTo}
              className="item"
              onChange={this.handleInput}
              items={this.props.ableList}
            />
          </div>
        </Panel>
        <Panel title="Normatividad" number="3.0">
          <Table
            headerContent={normativityHeaderContent}
            className="normativity-table"
          >
            {this.generateNormativity().map((ni) => {
              return (
                <NormativityTableItem 
                  isEditingOperator={this.state.modeEdit}
                  className="row"
                  id={ni.id}
                  description={ni.description}
                  documentType={ni.documentType}
                  key={ni.id}
                  name={ni.name}
                  value={this.state[ni.name]}
                  filesToHide={this.state.normativityImagesToDelete[ni.name]}
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                  showLimitFiles={() =>
                    this.setState({ showFilesMessageError: true })
                  }
                  confirmAction={
                    this.state.modeEdit
                      ? (e, action) => {
                          this.confirmDeleteAction = action;

                          let currentArray = this.state.normativityImagesToDelete[e.target.name];
                          let value = e.target.value;

                          if (value !== null) {
                            currentArray.push(value);
                            this.state.normativityImagesToDelete[e.target.name] = currentArray;

                            this.setState({});
                          }
                        }
                      : null
                  }
                />
              );
            })}
          </Table>
        </Panel>
        <Panel title="RFC" number="4.0">
          <TextInput
            label="RFC"
            name="rfc"
            value={this.state.rfc}
            className="item half_2"
            onChange={ (e) => {
              if (e.target.value === '') {
                this.state.country = 0;
                this.state.suburb = 0;
                this.state.street = "";
                this.state.numInt = "";
                this.state.numExt = "";
                this.state.postalCode = "";
                this.state.locality = 0;
                this.state.municipality = 0;
                this.state.stateOperator = 0;
                this.state.reference = "";
              }
              this.setState({ rfc: `${e.target.value}`.toUpperCase() })}
            }
            validate={this.state.rfc.length > 0 ? ['rfc'] : []}
            onFocused={this.state.onFocused} 
          />
          <TextInput
            label="Calle"
            name="street"
            value={this.state.street}
            className="item half_2"
            onChange={this.handleInput}
            onFocused={this.state.onFocused}
          />
          <div className="general-data">
            <TextInput
              label="Num ext."
              name="numExt"
              value={this.state.numExt}
              className="item third"
              onChange={this.handleInput}
              onFocused={this.state.onFocused}
            />
            <TextInput
              label="Num int."
              name="numInt"
              value={this.state.numInt}
              className="item third"
              onChange={this.handleInput}
              onFocused={this.state.onFocused}
            />
            <SelectInput
              label='País'
              className="item third"
              name="country" 
              items={this.props.countries || []}
              value={this.state.country}
              onChange={this.handleAddress}
              onFocused={this.state.onFocused}
              validate={this.state.rfc.length > 0 ? [validators.required] : []}
            />
            <TextInput
              type='number'
              label='Código Postal'
              name='postalCode'
              className='item third'
              value={this.state.postalCode}
              onChange={this.handleAddress}
              onFocused={this.state.onFocused}
              //disabled={!this.state.country}
              // validate={this.state.rfc.length > 0 ? [validators.required] : []}
            />
            <SelectInput
              label='Colonia'
              className="item third"
              value={this.state.suburb}
              name='suburb'
              items={this.state.neighborhoodOptions}
              onChange={this.handleAddress}
              disabled={!this.state.postalCode}
              onFocused={this.state.onFocused}
              validate={this.state.rfc.length > 0 ? [validators.required] : []}
            />
            {
              this.state.locality 
              ? <SelectInput
                label='Localidad'
                className="item third"
                value={this.state.locality}
                name='locality'
                items={this.state.localityOptions}
                onChange={this.handleAddress}
                disabled={!this.state.postalCode}
              />
              : ''
            }
            <SelectInput
              label='Municipio'
              className="item third"
              name="municipality"
              // items={cityList}
              items={this.state.cityOptions}
              value={this.state.municipality}
              onChange={this.handleAddress}
              disabled={!this.state.postalCode}
              onFocused={this.state.onFocused}
            />
            <SelectInput
              label='Estado'
              className="item third"
              name="stateOperator"
              items={this.state.stateOptions}
              value={this.state.stateOperator}
              onChange={this.handleAddress}
              disabled={!this.state.postalCode}
              onFocused={this.state.onFocused}
            />
            <TextInput
              label="Referencia"
              name="reference"
              value={this.state.reference}
              className="item third"
              onChange={this.handleInput}
              onFocused={this.state.onFocused}
            />
          </div>
        </Panel>
        <div className="actions">
          <Button
            settings={{
              type: "outline primary white",
              text: "Cancelar",
              onClick: () => this.props.history.goBack(),
            }}
          />
          <Button
            settings={{
              type: "primary",
              disabled: this.fetchCounter > 0 || !this.validateForm(),
              text: this.savingDataText(),
              onClick: () => {
                setTimeout(this.validateNormativities , 200);
              }
            }}
            dataTip={this.getErrors()}
            dataFor="orbi-tooltip-error"
            onMouseLeave={() => this.handleLeave()}
          />

          <ReactTooltip
            id={"orbi-tooltip-error"}
            place="top"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />
        </div>

        <ErrorDialog
          open={this.state.showError} 
          message={this.state.msgError}
          acceptText="OK" 
          acceptAction={() => {
            this.setState({
              showError: false,
            });
          }}
          closeAction={() => {
            this.setState({
              showError: false,
            });
          }}
        />

        <ErrorDialog
          open={this.state.showFilesSizeMessageError}
          message={"El archivo que intenta subir sobrepasa los 12 MB."}
          acceptText="Aceptar"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ErrorDialog
          open={this.state.showFilesMessageError}
          message={"El numero de archivos permitido es de 3"}
          acceptText="Aceptar"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ConfirmDialog
          open={this.state.showConfirmDialog}
          title="Eliminar imagen"
          message={"¿Estás seguro que deseas eliminar la imagen?"}
          acceptText="Eliminar"
          class="delete-tags"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={this.actionCloseDialog}
          acceptAction={() => {
            this.actionCloseDialog();
            this.confirmDeleteAction();
            this.deleteImage();
          }}
        />

        <ConfirmDialog
          closeAction={this.closeImageEditModal}
          open={this.state.editImage}
          title={"Edita tu imagen"}
          message={"Edita tu imagen"}
          acceptText={"Guardar"}
          acceptAction={this.AcceptImageEdit}
          showCancel={true}
          cancelText={"Cancelar"}
          class="no-bottom-margin"
          contentObject={
            <ReactCrop
              src={this.state.imageToCrop}
              crop={this.state.crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              crossorigin="anonymous"
              style={{ maxHeight: "340px", overflow: "auto", margin: "10px" }}
            />
          }
        />
        <ConfirmDialog
          open={this.state.saveExit || false}
          title="Actualizando estatus de normatividad"
          disabledAccept={true}
        />

        <ConfirmDialog
          open={this.state.normativitiesChangedConfirmation}
          title="Normatividades"
          message="Las modificaciones en datos de Licencia y/o Certificado de aptitud psicofísica también se aplicarán a las normatividades de las otras transportistas donde se encuentra vinculado éste operador"
          showCancel
          closeAction={() => this.setState({normativitiesChangedConfirmation: false})}
          acceptAction={() => {
            this.setState({normativitiesChangedConfirmation: false});
            this.handleDeleteDocs();
          }}
        />
        
      </div>
    );
  }

  closeImageEditModal = () => {
    this.setState({ editImage: false });
  };
  AcceptImageEdit = () => {
    this.setState({
      profileImage: this.state.croppedImageUrl,
      editImage: false,
    });
  };
  onImageLoaded = (image) => {
    this.imageRef = image;
  };
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };
  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        var f = new File([blob], "image.jpeg");
        resolve(f);
      }, "image/jpeg");
    });
  }

  handleAddDocument = (e) => {
    let filesNumber = e.target.value.length;

    switch (e.target.name) {
      case "license":
        filesNumber += +this.state.license.length;
        break;
      case "fitness":
        filesNumber += +this.state.fitness.length;
        break;
      case "medical":
        filesNumber += +this.state.medical.length;
        break;
      default:
        break;
    }

    if (filesNumber > 3) {
      this.setState({ showFilesMessageError: true });
    } else {
      let value = (this.state[e.target.name] || []).concat(e.target.value);
      this.handleInput({ target: { name: e.target.name, value: value } });
    }
  };

  handleRemoveDocument = (name, file) => {
    if (file.idNormativityImage !== undefined) {
      switch (name) {
        case "license":
          this.setState({
            fileIdToRemove: file.idNormativityImage,
            normativityIdToRemove: this.state.normLicenseId,
            showConfirmDialog: true,
          });

          break;

        case "fitness":
          this.setState({
            fileIdToRemove: file.idNormativityImage,
            normativityIdToRemove: this.state.normFitnessId,
            showConfirmDialog: true,
          });

          break;

        case "medical":
          this.setState({
            fileIdToRemove: file.idNormativityImage,
            normativityIdToRemove: this.state.normMedicalId,
            showConfirmDialog: true,
          });

          break;

        default:
          break;
      }

      this.confirmDeleteAction = () => {
        let value = this.state[name].filter(
          (fi) => fi.idNormativityImage !== file.idNormativityImage
        );
        this.handleInput({ target: { name: name, value: value } });
      };
    } else {
      var fileRemoved = false;

      let value = this.state[name].filter((fi) => {
        if (fileRemoved || fi.name !== file.name || fi.size !== file.size) {
          return true;
        }

        fileRemoved = true;
        return false;
      });

      this.handleInput({ target: { name: name, value: value } });
    }
  };
}

const mapStateToProps = (state) => {
  const country = state.globalCatalog.session.company.country__description;
  const fleets = state.fleetReducer.fleets.data;
  const operateType = state.fleetReducer.operateType.data;
  const countries = getEntityItems(state, "COMPANIES.SAT.ADDRESS.CATALOGS.COUNTRY")
    .map(c => ({id: c.id, description: c.descripcion}))
    .sort((a, b) => {if(a.description > b.description) return 1; if(a.description < b.description) return -1; return 0});

  return {
    countries,
    country,
    toast: state.globalCatalog.toast,
    company: state.globalCatalog.session.company,
    tagsData: state.fleetReducer.tags.data,
    fleet: Array.isArray(fleets) && fleets.length > 0 ? fleets[0] : null,
    fleetData: state.fleetReducer.fleetData.fleets,
    ableList: Array.isArray(operateType) ? operateType : [],

    fleetTagsCatalog: Array.isArray(state.tags.tags)
      ? state.tags.tags
          .filter(
            (tag) =>
              tag.type ===
              (
                (Array.isArray(state.tags.tagTypes.data)
                  ? state.tags.tagTypes.data
                  : []
                ).find(
                  (type) => type.description.toLowerCase() === "flotilla"
                ) || {}
              ).id
          )
          .map((tag) => ({
            id: tag.id,
            description: tag.title,
            color: tag.color,
          }))
      : [],

    normativityItems: normativityItems,
    typeCategoryCatalog: Array.isArray(state.fleetReducer.operatorType.data)
      ? state.fleetReducer.operatorType.data
      : [],
    expeditionCenterCatalog: Array.isArray(
      state.fleetReducer.expeditionCenter.data
    )
      ? state.fleetReducer.expeditionCenter.data
          .filter((item) => item.visible === true)
          .map((item) => {
            return {
              id: item.description,
              created: item.created,
              modified: item.modified,
              visible: item.visible,
              description: item.description,
            };
          })
      : [],
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(loadOperatorTypes());
  dispatch(loadTags());
  dispatch(loadFleets());
  dispatch(loadOperateType());
  dispatch(loadExpeditionCenter());

  return {
    loadTaxInfo: (params, opt) => dispatch(readEntity("COMPANIES.SAT.TAXINFO.OPERATOR", params, opt)),
    checkZipCode: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.ADDRESS.ZIPCODECHECKBYCOUNTRY", params, opt) 
      );
    },
    loadCountries: () =>{
      dispatch(
        readEntities("COMPANIES.SAT.ADDRESS.CATALOGS.COUNTRY", {}, {prefix: 'orbisat.'}) 
      );
    },
    updateFleetDataV2: (params, opt) => 
      dispatch(partialEntity("COMPANIES.FLEETS.DATA.OPERATORS", params, opt)),
    createFleetDataV2: (params, opt) => 
      dispatch(createEntity("COMPANIES.FLEETS.DATA.OPERATORS", params, opt)),
    deleteFileV2: (params, opt) => 
      dispatch(deleteEntity("COMPANIES.FLEETS.DATA.OPERATORS", params, opt)),
    cloningFleetDataV2: (params, opt) => 
      dispatch(createEntity("COMPANIES.FLEETS.DATA.OPERATORS.CLONING", params, opt)),
    updateStatusFleetDataV2: (params, opt) => 
      dispatch(createEntity("COMPANIES.FLEETS.DATA.OPERATORS.UPDATESTATUS", params, opt)),
    loadMobileUser: (userId) => dispatch(loadMobileUser(userId)),
    showToast: (payLoad) => dispatch(addToast(payLoad)),
    createUserMobile: (objUser) => dispatch(createUserMobile(objUser)),
    updateUserMobile: (idUser, objUser) =>
      dispatch(updateUserMobile(idUser, objUser)),
    assignCompany: (company_id, user_id) =>
      dispatch(assignCompany(company_id, user_id)),
    createOperator: (fleetId, fleetDataObj) =>
      dispatch(createOperator(fleetId, fleetDataObj)),
    updateOperator: (operadorId, fleetId, fleetDataObj) =>
      dispatch(updateOperator(operadorId, fleetId, fleetDataObj)),
    loadOperatorData: (fleetId, operatorId) =>
      dispatch(loadOperatorData(fleetId, operatorId)),
    loadOperatorNormativities: (fleetId, operatorId) =>
      dispatch(loadOperatorNormativities(fleetId, operatorId)),
    loadImageOperatorNormativities: (fleetId, operatorId, normId) =>
      dispatch(loadImageOperatorNormativities(fleetId, operatorId, normId)),
    loadOperatorTags: (fleetId, operatorId) =>
      dispatch(loadOperatorTags(fleetId, operatorId)),
    addTagToOperator: (fleet_id, operator_id, tagDataObj) =>
      dispatch(addTagToOperator(fleet_id, operator_id, tagDataObj)),
    deleteTagInOperador: (fleet_id, operator_id, tagDataObj) =>
      dispatch(deleteTagInOperador(fleet_id, operator_id, tagDataObj)),
    createNormativityDataForOperator: (
      fleet_id,
      operator_id,
      normativityDataObj
    ) =>
      dispatch(
        createNormativityDataForOperator(
          fleet_id,
          operator_id,
          normativityDataObj
        )
      ),
    updateNormativityDataForOperator: (
      fleet_id,
      operator_id,
      normativityDataObj,
      normId
    ) =>
      dispatch(
        updateNormativityDataForOperator(
          fleet_id,
          operator_id,
          normativityDataObj,
          normId
        )
      ),
    postUploadImageOperator: (fleetId, operatorId, normativityId, file) =>
      dispatch(
        postUploadImageOperator(fleetId, operatorId, normativityId, file)
      ),
    deleteNormativityImagesForOperator: (
      fleetId,
      operatorId,
      normativityId,
      imageId
    ) =>
      dispatch(
        deleteNormativityImagesForOperator(
          fleetId,
          operatorId,
          normativityId,
          imageId
        )
      ),

    postValidateUsername: (username) =>
      dispatch(postValidateUsername(username)),
    postValidateId: (fleet_id, id) => dispatch(postValidateId(fleet_id, id)),
    postValidateLicense: (fleet_id, licensenum) =>
      dispatch(postValidateLicense(fleet_id, licensenum)),
    postValidateAptitude: (fleet_id, aptitude) =>
      dispatch(postValidateAptitude(fleet_id, aptitude)),
    postValidateSecurity: (fleet_id, socialsecurity) =>
      dispatch(postValidateSecurity(fleet_id, socialsecurity)),
    updateOperatorNormativityStatus: (companyId, fleetId, dataId) =>
      dispatch(updateOperatorNormativityStatus(companyId, fleetId, dataId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetOperatorView);

const normativityItems = [
  {
    id: 1,
    description: "Foto de licencia",
    documentType: DocumentTypes.image,
    label: "N° ",
    name: "license",
  },
  {
    id: 2,
    description: "Constancia de aptitud",
    documentType: DocumentTypes.file,
    label: "N° ",
    name: "fitness",
  },
  {
    id: 3,
    description: "Número del IMSS",
    documentType: DocumentTypes.file,
    label: "N° ",
    name: "socialSecurity",
  },
];

const normativityHeaderContent = [
  { text: "Nombre documento", width: "30%" },
  { text: "Documento", width: "15%" },
  { text: "Número del documento", width: "auto" },
  { text: "Fecha de expedición", width: "12%" },
  { text: "Fecha de expiración", width: "12%" },
];
