export const validationErrors = {
  required: "Campo requerido",
  maxLength: "Longitud invalida",
  rfc: "Formato no válido",
  moralRfc: "Formato no válido",
  physicalRfc: "Formato no válido",
  lessThanDate: "La fecha no puede ser mayor a el día de hoy",
  password: "No cumple las reglas"
  // minLength: 'Longitud invalida',
};

const validateFunctions = {
  required: (value) => {
    /* debugger; */ return (
      value && (typeof value === "string" ? value.length > 0 : true)
    );
  },
  maxLength: (value, length) =>
    value && length ? value.length <= length : true,
  rfc: (value) => {
    const re = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/;
    return re.test(value);
  },
  moralRfc: (value) => {
    const re = /^([A-ZÑ\x26]{3}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/;
    return re.test(value);
  },
  physicalRfc: (value) => {
    const re = /^([A-ZÑ\x26]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/;
    return re.test(value);
  },
  lessThanDate: (value) => {
    if (`${value}` === '') {
      return true;
    }

    let tempDate = new Date(value);

    let year = tempDate.getFullYear();
    let month = tempDate.getMonth() + 1;
    let day = tempDate.getDate();

    let new_date = new Date(`${month}/${day}/${year}`);
    let currentDate = new Date();

    return new_date < currentDate;
  },
  password: (value) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,123}$/;
    return re.test(value);
  }
  // minLength: (value, length) => value && length && value.length >= length
};

export const validators = {
  required: "required",
  password: "password",
  // minLength: 'minLength'
};

export const validateControl = (validations, value, params = 0) => {
  let errors = [];
  if (Array.isArray(validations)) {
    validations.forEach((validation) => {
      errors = validateFunctions[validation](value, params)
        ? errors
        : [...errors, validationErrors[validation]];
    });
  }
  return { validationResult: { valid: !errors.length > 0, errors: errors } };
};

export const validatePassword = (text) => {
  const rfcRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,123}$/;
  return rfcRegEx.test(text);
}

export const validateRFC = (text) => {
  const rfcRegEx = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/;
  return rfcRegEx.test(text);
}

export const validateMoralRFC = (text) => {
  const rfcRegEx = /^([A-ZÑ\x26]{3}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/;
  return rfcRegEx.test(text);
}

export const validatePhysicalRFC = (text) => {
  const rfcRegEx = /^([A-ZÑ\x26]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/;
  return rfcRegEx.test(text);
}

export const validateEmail = (email) => {
  // eslint-disable-next-line
  let emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegEx.test(String(email).toLowerCase());
};

export const validateWebPage = (webPage) => {
  const webPageRegEx = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/;
  return webPageRegEx.test(String(webPage).toLowerCase());
};

export const validateWebDomain = (webDomain) => {
  const webDomainRegEx = /^@[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
  const webSubDomainRegEx = /^@[a-zA-Z0-9]+([a-zA-Z0-9-]+\.[a-zA-Z0-9-]+){1,61}[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
  return (
    webDomainRegEx.test(String(webDomain).toLowerCase()) ||
    webSubDomainRegEx.test(String(webDomain).toLowerCase())
  );
};

export const validateLettersAndNumbers = (text) => {
  let regEx = /[^A-Za-z0-9]+$/;
  return regEx.test(String(text).toLowerCase());
};

export const removeSpecialCharacters = (text) => {
  // let regEx = /[^A-Za-z0-9]+$/;
  return text.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
};
