import React, { Component } from "react";
import PropTypes from "prop-types";
import SVGIcon, { icons } from "../../svg-icon";
import Button from "../../controls/button";
import TextInput from "../../controls/text-input";
import FileSelector, { acceptTypes } from "../../controls/file-selector";
import Chip from "../../controls/chip";
import ExceedPlus from "../../controls/exceed-plus";

export const DocumentTypes = {
  image: "imagen",
  file: "archivo",
};

class NormativityTableItem extends Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.number,
    description: PropTypes.string,
    documentType: PropTypes.oneOf([DocumentTypes.image, DocumentTypes.file]),
    label: PropTypes.string,
    value: PropTypes.object,
    expedition: PropTypes.string,
    expiration: PropTypes.string,
    considered: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    label: "",
    considered: false,
    extraField: null,
  };

  componentDidUpdate(prevProps) {

    let prev_expiration = prevProps.value?.expiration ?? '';
    let expiration = this.props.value?.expiration ?? '';

    if (prev_expiration !== expiration) {

      let expirationDate = new Date(expiration);
      let currentDate = new Date();

      if (expirationDate < currentDate) {
        let property = this.props.name;
        let value = Object.assign(this.props.value, {
          expiration: expiration,
          expirationErrors: expiration === '' ? ['Campo requerido'] : ['La fecha ya pasó']
        });

        this.props.onChange({ target: { name: property, value: value } });

      }

    }

  }

  handleAddDocument = (e) => {
    const filesNumber = e.target.value.length + this.getFilesToShow().length;

    if (filesNumber > 3) {
      this.props.showLimitFiles();
    } else {
      let value = (this.props.value[e.target.name] || []).concat(
        e.target.value
      );
      this.handleInput({ target: { name: e.target.name, value: value } });
    }
  };

  handleRemoveDocument = (file) => {
    if (file.idNormativityImage === undefined) {
      var fileRemoved = false;

      let value = this.props.value["files"].filter((fi) => {
        if (fileRemoved || fi.name !== file.name || fi.size !== file.size) {
          return true;
        }

        fileRemoved = true;
        return false;
      });
      this.handleInput({ target: { name: "files", value: value } });
    } else {
      let stateBeforeAction = null;
      let value = null;

      if (this.props.isEditingOperator) {
        value = this.props.value["files"].filter(
          (fi) => fi.idNormativityImage === file.idNormativityImage
        );
        stateBeforeAction = {
          target: { name: this.props.name, value: (value && value.length > 0) ? value[0] : null },
        };
      } else {
        value = this.props.value["files"].filter(
          (fi) => fi.idNormativityImage !== file.idNormativityImage
        );
        stateBeforeAction = {
          target: { name: this.props.name, value: value },
        };
      }

      if (this.props.confirmAction)
        this.props.confirmAction(stateBeforeAction, () => {
          this.handleInput({ target: { name: "files", value: value } });
        });
    }
  };

  handleInput = (e) => {
    if (e.target.name === "expiration") {
      let expirationDate = new Date(e.target.value);
      let currentDate = new Date();
      if (expirationDate > currentDate) {
        let property = this.props.name;
        let value = Object.assign(this.props.value, {
          [e.target.name]: e.target.value,
          expirationErrors: []
        });
        this.props.onChange({ target: { name: property, value: value } });
      } else {
        let property = this.props.name;
        let value = Object.assign(this.props.value, {
          [e.target.name]: e.target.value,
          expirationErrors: e.target.value === '' ? ['Campo requerido'] : ['La fecha ya pasó']
        });
        this.props.onChange({ target: { name: property, value: value } });
      }
    } else {
      let property = this.props.name;
      let value = Object.assign(this.props.value, {
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "expedition") {
        let expeditionDate = new Date(e.target.value);

        let year = expeditionDate.getFullYear();
        let month = expeditionDate.getMonth() + 1;
        let day = expeditionDate.getDate();

        let expirationDate = new Date(`${month}/${day}/${year}`);
        let currentDate = new Date();

        if (expirationDate > currentDate) {

          let value2 = Object.assign(this.props.value, {
            expeditionErrors: ['La fecha no puede ser mayor a el día de hoy']
          });

          this.props.onChange({ target: { name: 'expedition', value: value2 } });

          return;
        }
      }

      if (this.props.description === 'Autorización expresa' && e.target.name === "expedition") {

        if (e.target.value === "") {
          let value2 = Object.assign(this.props.value, {
            ['expiration']: "",
          });
          this.props.onChange({ target: { name: 'expiration', value: value2 } });
        } else {
          let expeditionDate = new Date(e.target.value);

          let year = expeditionDate.getFullYear() + 3;
          let month = expeditionDate.getMonth() + 1;
          let day = expeditionDate.getDate();

          let expirationDate = new Date(`${month}/${day}/${year}`);
          let currentDate = new Date();

          let value2 = Object.assign(this.props.value, {
            ['expiration']: `${month}/${day}/${year}`,
            expirationErrors: expirationDate < currentDate ? ['La fecha ya pasó'] : [] 
          });

          this.props.onChange({ target: { name: 'expiration', value: value2 } });
        }

      } else if (this.props.description === 'Dictamen de baja emisión de contaminantes' && e.target.name === "expedition") {

        if (e.target.value === "") {
          let value2 = Object.assign(this.props.value, {
            ['expiration']: "",
          });
          this.props.onChange({ target: { name: 'expiration', value: value2 } });
        } else {

          let expeditionDate = new Date(e.target.value);

          let year = expeditionDate.getFullYear() + 1;
          var month = expeditionDate.getMonth();
          var day = expeditionDate.getDate();

          if (month <= 5) {
            month = 6;
            day = 30;
          } else {
            month = 12;
            day = 31;
          }

          let expirationDate = new Date(`${month}/${day}/${year}`);
          let currentDate = new Date();

          let value2 = Object.assign(this.props.value, {
            ['expiration']: `${month}/${day}/${year}`,
            expirationErrors: expirationDate < currentDate ? ['La fecha ya pasó'] : [] 
          });
          
          this.props.onChange({ target: { name: 'expiration', value: value2 } });

        }

      } else if ((this.props.description === 'Certificado de condición físico-mecánico' || this.props.description === 'Certificado de condición físico - mecánico') && e.target.name === "expedition") {
        
        if (e.target.value === "") {
          let value2 = Object.assign(this.props.value, {
            ['expiration']: ""
          }); 
          
          this.props.onChange({ target: { name: 'expiration', value: value2 } });
        } else {
          let expeditionDate = new Date(e.target.value);
          let lastCharacter = null;

          if (this.props.extraInfo !== undefined && this.props.extraInfo !== '') {
            lastCharacter = parseInt(this.props.extraInfo.slice(-1))
          }

          if (lastCharacter !== null && lastCharacter !== undefined && lastCharacter !== NaN) {

            let month = null;
            let day = null;
            
            let year = expeditionDate.getFullYear() + 1;
            
            if (lastCharacter === 5 || lastCharacter === 6) {
              month = 4;
              day = 30;
            } else if (lastCharacter === 7 || lastCharacter === 8) {
              month = 5;
              day = 30;
            } else if (lastCharacter === 3 || lastCharacter === 4) {
              month = 8;
              day = 31;
            } else if (lastCharacter === 1 || lastCharacter === 2) {
              month = 10;
              day = 31;
            } else if (lastCharacter === 9 || lastCharacter === 0) {
              month = 12;
              day = 31;
            }

            if (month !== null) {

              let expirationDate = new Date(`${month}/${day}/${year}`);
              let currentDate = new Date();

              let value2 = Object.assign(this.props.value, {
                ['expiration']: `${month}/${day}/${year}`,
                expirationErrors: expirationDate < currentDate ? ['La fecha ya pasó'] : [] 
              }); 
              
              this.props.onChange({ target: { name: 'expiration', value: value2 } });

            }

          } 
        }

      }

      let value2 = Object.assign(this.props.value, {
        expeditionErrors: []
      });

      this.props.onChange({ target: { name: property, value: value2 } });
    }
  };

  getIcon = () => {
    return this.props.documentType === DocumentTypes.image
      ? icons.uploadImage
      : icons.uploadFile;
  };

  getDescription = (f) => {
    if (f.size) {
      let size = f.size;
      let magnitude = ["B", "KB", "MB", "GB"];
      let magCount = 0;
      while (size > 1024) {
        size /= 1024;
        magCount++;
      }
      return (f.name.length > 10
        ? f.name.substring(0, 6).concat("...")
        : f.name
      ).concat(" (", size.toFixed(2), magnitude[magCount], ")");
    } else {
      return String(f.name).length > 10
        ? String(f.name).substring(0, 10) + "... "
        : f.name;
    }
  };

  getFilesToShow = () => {
    let values = this.props.value.files || [];
    let notShow = this.props.filesToHide || [];

    if (this.props.isEditingOperator) {
      return values.filter( item => !notShow.some(s => s.idNormativityImage === item.idNormativityImage) );
    } else {
      return values;
    }
  }

  // static defaultProps = {};
  render() {
    return (
      <div
        className={"normativity-item ".concat(this.props.className)}
        key={this.props.id}
      >
        <div style={{ width: this.props.headers[0].width }}>
          <label>{this.props.description}</label>
          {
            this.props.extraField && (
              <div style={{ display: "inline", float: "right", maxWidth: "50%" }}>
                {this.props.extraField}
              </div>
            )
          }
          {this.props.considered && (
            <div style={{ display: "inline", float: "right" }}>
              <label>
                <input
                  type="checkbox"
                  name={`checkbox-${this.props.description}`}
                  value={0}
                  onClick={(e) =>
                    this.props.handleInputCheckbox(e, this.props.name)
                  }
                  checked={!this.props.value.isConsidered}
                  // checked={
                  //   String(this.props.defaultValue) === String(item.value)
                  //     ? true
                  //     : false
                  // }
                  style={{ marginRight: "10px" }}
                />
                NO aplica
              </label>
            </div>
          )}
          <div className="files">
            <ExceedPlus
              maxVisibleItems={1}
              name={this.props.id + ""}
              onTooltipClassname="cardToolTip"
            >
              {this.getFilesToShow().map((f, i) => (
                <Chip
                  key={i}
                  id={i}
                  active={true}
                  className="white small"
                  name="files"
                  description={this.getDescription(f)}
                  onClick={() => {}}
                  prefix={{
                    element: (
                      <SVGIcon
                        name={icons.imageFile}
                        fill="#657795"
                        viewBox="0 -1 16 16"
                      />
                    ),
                  }}
                  suffix={{
                    element: (
                      <div onClick={() => this.handleRemoveDocument(f)}>
                        {" "}
                        <SVGIcon
                          name={icons.cross}
                          fill="#A7B6D6"
                          viewBox="0 3 16 16"
                        />
                      </div>
                    ),
                  }}
                />
              ))}
            </ExceedPlus>
          </div>
        </div>
        <div style={{ width: this.props.headers[1].width }}>
          {
            this.props.value.isConsidered ?
            <FileSelector
              name={"files"}
              acceptTypes={[acceptTypes.imagesAndPdf]}
              onChange={this.handleAddDocument}
              allowMultiple={true}
            >
              <Button
                settings={{
                  type: "primary small",
                  text: "Subir ".concat(this.props.documentType),
                  prefix: {
                    element: (
                      <SVGIcon
                        name={this.getIcon()}
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                      />
                    ),
                  },
                }}
              />
            </FileSelector>
            : <div/>
          }
        </div>
        <div style={{ width: this.props.headers[2].width }}>
          {this.props.description !== "Constancia de aptitud psicofisica" &&
          this.props.description !== "Licencia de conducir (ambos lados)" ? (
            this.props.value.isConsidered ?
            <div style={{ width: "158px" }}>
              <TextInput
                value={this.props.value.number}
                label={this.props.label || ""}
                name="number"
                onChange={this.handleInput}
              />
            </div> : <div style={{ width: "158px" }}/>
          ) : (
            <div style={{ width: "158px" }}></div>
          )}
        </div>
        <div style={{ width: this.props.headers[3].width }}>
          {
            this.props.value.isConsidered ?
            <TextInput
              type="date"
              value={this.props.value.expedition}
              label={this.props.value.expeditionLabel || "Fecha"}
              name="expedition"
              onChange={this.handleInput}
              suffix={{
                element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
              }}
              format={"dd/MMM/yyyy"}
              errors={this.props.value.expeditionErrors || []}
            /> : <div style={{ width: "158px" }}/>
          }
          
        </div>
        <div style={{ width: this.props.headers[4].width }}>
          {this.props.description !== "Tarjeta de circulación" &&
            this.props.description !== "Constancia de registro de IMSS" && (
              this.props.value.isConsidered ? 
              <TextInput
                type="date"
                value={this.props.value.expiration}
                label={this.props.value.expirationLabel || "Fecha"}
                name="expiration"
                onChange={this.handleInput}
                disabled={this.props.value.expedition !== "" ? false : true}
                minDate={this.props.value.expedition}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
                format={"dd/MMM/yyyy"}
                errors={this.props.value.expirationErrors || []}
              /> 
              : <div style={{ width: "158px", height: "86px" }}/>
            )}
        </div>
      </div>
    );
  }
}

export default NormativityTableItem;
